export const menuList = [
  {
    header: "FINANCE",
    submenu: [
      {
        name: "Inbound Settlement",
        url: "/inbound-settlement",
        access: "INBOUND_SETTLEMENT",
      },
      {
        name: "Settlements",
        url: "/manual-input",
        access: "FINANCE SECTION INCL. SETTLEMENTS",
      }
    ],
  },
  {
    header: "REPORTS",
    submenu: [
      {
        name: "Generate Reports",
        url: "/generate-reports",
        access: "REPORTS",
      },
      {
        name: "Transactions",
        url: "/transactions",
        access: "REPORTS_MERGED",
        permission: true
      }
    ],
  },
  {
    header: "SETTINGS",
    submenu: [
      {
        name: "System Logs",
        url: "/logs",
        access: "SYSTEM_LOGS"
      },
      {
        name: "Admin Panel",
        url: "/admin-panel",
        access: "ADMIN_PANEL",
      },
      {
        name: "Bank Codes",
        url: "/bank-codes",
        access: "BANKLIST",
      },
      {
        name: "Bank Codes Export",
        url: "/bank-codes-export",
        access: "BANKLIST",
      },
      {
        name: "Generate Order",
        url: "/generate-order",
        access: "GENERATE_ORDER",
      },
      {
        name: "User Management",
        url: "/user-management",
        access: "USER_MANAGEMENT",
      },
      {
        name: "Merchants",
        url: "/merchants",
        access: "MERCHANTS",
      },
      {
        name: "MIDs",
        url: "/mids",
        access: "MIDS",
      },
      {
        name: "White Labels",
        url: "/white-labels",
        access: "COMPANIES",
      },
      {
        name: "My Account",
        url: "/my-account",
        access: "MY_ACCOUNT",
      },
      {
        name: "Onboarding Process",
        url: "/onboarding-process",
        access: "MIDS",
      },
      {
        name: "Cascading",
        url: "/provider-priority",
        access: "CASCADING",
      },
      {
        name: "Cascading Payout",
        url: "/provider-payout-priority",
        access: "CASCADING",
      },
      {
        name: "Providers",
        url: "/providers",
        access: "PROVIDERS",
      },
      {
        name: "Currency Table",
        url: "/currency-table",
        access: "CURRENCY_TABLE",
      }
    ],
  },
  {
    header: "RISK MANAGEMENT",
    submenu: [
      {
        name: "Blacklist",
        url: "/blacklist",
        access: "BLACKLIST"
      }
    ],
  },
  {
    header: "SOFTWARE INTEGRATION",
    submenu: [
      {
        name: "API",
        url: "/docs",
        access: "API",
      },
      {
        name: "Notes",
        url: "/notes",
        access: "NOTES",
      },
    ],
  },
  {
    header: "PAYOUTS",
    submenu: [
      {
        name: "Import Payouts",
        url: "/import-payouts",
        access: "PAYOUT_EXCEL_UPLOAD",
        permission: true,
      }
    ],
  },
  {
    header: "MARKETING",
    submenu: [
      {
        name: "Pending Agents",
        url: "/pending-agents",
        access: "REFERRALS",
      },
      {
        name: "Pending Referrals",
        url: "/pending-referrals",
        access: "REFERRALS",
      },
      {
        name: "Agent Management",
        url: "/agent-management",
        access: "USER_MANAGEMENT",
      },
      {
        name: "Referral Links",
        url: "/referral-links",
        access: "REFERRAL_LINKS",
        permission: true,
      },
    ],
  },
];
