import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Column, Row } from 'simple-flexbox';

import EmptySignupScreen from '@modules/signup/EmptySignupScreen';
import Header from '@sm/components/Header';
import Login from '@modules/login/Login';
import Module from '@sm/components/Module';
import ScrollToTop from '@sm/components/functional-components/ScrollToTop';
import SideBar from '@sm/components/SideBar';
import SignUp from '@modules/signup/SignUp';

import "bootstrap/dist/css/bootstrap.min.css";
import '@assets/css/main.css';

import { frontEndUrl } from "@sm/config";

const broadcastChannel = new BroadcastChannel('logout');

class App extends Component {
  state = {
    userLoggedIn: false,
    loggedAsUser: false
  };

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    const loggedAsUserToken = JSON.parse(localStorage.getItem('loginAsUser'));
    const loggedAsUserEmail = localStorage.getItem('loginAsUserEmail');

    if ((user && user.accessToken) || loggedAsUserToken) {
      
      this.setState({
        userLoggedIn: true,
        ...((loggedAsUserToken) && { loggedAsUser: true }),
        ...((loggedAsUserEmail) && { loggedAsUserEmail: loggedAsUserEmail })
      });
    } else {
      localStorage.removeItem('loginAsUser');
      localStorage.removeItem('loginAsUserEmail');
    }
  };

  componentDidUpdate() {
    broadcastChannel.onmessage = (messageEvent) => {
      if (messageEvent.data === "Logout") {
        this.logout();
        broadcastChannel.close();
      }
      if (messageEvent.data.action === "Login") {
        this.setState({
          loggedAsUser: true,
          loggedAsUserEmail: messageEvent.data.email
        });
      }
    };
  };

  handleCloseLoginAsMode = () => {
    this.logout();
  };

  logout = () => {
    broadcastChannel.postMessage('Logout');
    localStorage.removeItem('loginAsUser');
    localStorage.removeItem('loginAsUserEmail');
    window.location.reload();
  };

  isSignUpScreen() {
    const currentLocation = window.location.href;
    return currentLocation.includes(frontEndUrl + "/sign-up");
  };

  render() {
    const { loggedAsUser, loggedAsUserEmail, userLoggedIn } = this.state;

    return (
      <div>
        <BrowserRouter>
          <ScrollToTop>
            <Route>
              { !this.isSignUpScreen() ? (
                <Column
                  className={ `starmids content ${userLoggedIn ? 'logged-in' : 'non-logged-in'} ${loggedAsUser ? 'view-only' : ''}` }
                  style={ { zoom: userLoggedIn ? 0.9 : 1 } }
                >
                  {loggedAsUser && (
                    <Row flexGrow={ 1 } className="logged-as-user">
                      <h4 className="title"> You&apos;re now logged in as <span>{ loggedAsUserEmail }</span> </h4>
                      <button
                        className="close-btn"
                        onClick={ () => this.handleCloseLoginAsMode() }
                      >
                        End session
                      </button>
                    </Row>
                  )}
                  <Header userLoggedIn={ userLoggedIn || loggedAsUser }/>
                  <Row flexGrow={ 1 }>

                    { userLoggedIn &&
                    	<Column className="sidebarColumn">
                    	  <SideBar />
                    	</Column>
                    }

                    <Column className="contentColumn overflow-x-auto" style={ { width: '100%' } }>
                      { userLoggedIn ?  <Module /> : <Login /> }
                    </Column>
                  </Row>
                </Column>
              ) : (
                <Column className="starmids content">
                  <Row flexGrow={ 1 }>
                    <Column className="contentColumn" style={ { width: '100%' } }>
                      <Route exact path="/sign-up" component={ EmptySignupScreen }/>
                      <Route exact path="/sign-up/:product" component={ SignUp }/>
                    </Column>
                  </Row>
                </Column>
              )}
            </Route>
          </ScrollToTop>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;