import React, { Component } from 'react';
import { Tooltip } from 'react-tooltip';
import { Column, Row } from 'simple-flexbox';
import { Panel } from "react-bootstrap";

import Alert from '@sm/components/custom/Alert';
import CustomButton from "@sm/components/custom/Button";
import ClientsTableTiers from '@modules/clients/ClientsTableTiers';
import DefaultCommercialSettings from '@modules/clients/DefaultCommercialSettings';
import PageHeader from "@sm/components/PageHeader";
import Form from "@sm/core/Form";
import Modal from '@sm/components/custom/Modal';
import Multiselect from "@sm/components/custom/customMultiselect";
import Select from '@sm/components/custom/Select';
import Spinner from '@sm/components/Spinner';
import Toggler from '@sm/components/custom/Toggler';

import '@assets/css/clients.css';
import '@assets/css/editClient.css';
import GenerateIcon from '@assets/images/generateIcon.png';
import MerchantsIcon from '@assets/images/merchants.png';
import VisibilityIcon from '@assets/images/visibilityIcon.png';
import VisibilityOffIcon from '@assets/images/visibilityOffIcon.png';

import { PASSWORD_REGEX } from "@sm/constants";
import { crudActions } from "@sm/services/crudActions";
import { twoFAOptions } from "@sm/utils/twoFAOptions";
import { addLogItem, generatePassword, generateApiKey } from "@sm/utils/utils";
import { FETCH_PERMISSIONS } from '@sm/actions/types';

const store =  require('@sm/reducers/index');

class ManageClient extends Component {
  state = {
    panels: [{
      title: "MERCHANT INFORMATION",
      form: "mainForm",
      rows: [
        [{
          id: "whiteLabelId",
          label: "White Label",
          type: "select",
          options: "companies"
        }, {
          id: "name",
          label: "Merchant Name"
        }, {
          id: "shortName",
          label: "Company Name"
        }, {
          id: "returnUrl",
          label: "Return URL"
        }, {
          id: "url",
          label: "Base URL",
          disabled: false
        }, {
          id: "apiKey",
          label: "Internal API Key",
          disabled: false
        }, {
          id: "postbackUrl",
          label: "Postback URL"
        }, {
          id: "payoutPostbackUrl",
          label: "Payout Postback URL"
        }, {
          id: "merchantUrl",
          label: "Merchant URL"
        }, {
          id: "applicationType",
          label: "Application Type",
          type: "select",
          options: "applicationTypes"
        }, {
          id: "apiKeyDetails",
          btnLabel: "Api Keys Details",
          type: "apiKeyDetails"
        }, {
          id: "isMonolith",
          checkboxLabel: "Monolith",
          disabled: false,
          type: "checkbox"
        }, {
          id: "isSandbox",
          checkboxLabel: "Sandbox",
          disabled: false,
          type: "checkbox",
          isDependent: true,
          dependencyField: "isMonolith"
        }, {
          id: "payoutHashVerification",
          checkboxLabel: "Payout Hash Verification",
          type: "checkbox",
          isDependent: true,
          dependencyField: "checkPayoutHash"
        }],
        [{
          id: "email",
          label: "Email"
        }, {
          id: "skype",
          label: "Skype ID"
        }, {
          id: "telegram",
          label: "Telegram ID"
        }, {
          id: "phone",
          label: "Phone"
        }],
        [{
          id: "login",
          label: "Email/Username"
        }, {
          id: "password",
          label: "Password",
          input: "password"
        }, {
          id: "confirmPassword",
          label: "Confirm Password"
        }, {
          id: "actions",
          type: "generatePassword"
        }],
        [{
          id: "enableTwoFactorAuth",
          label: "2FA",
          type: "select",
          options: "twoFAOptions"
        }, {
          id: "resetQR",
          btnLabel: "Reset 2FA QR",
          type: "resetQRSettings"
        }],
        [{
          id: "agentProgramCurrency",
          label: "Currency",
          disabled: true 
        }, {
          id: "gateway",
          label: "Gateway",
          type: "select",
          options: "gateways"
        }, {
          id: "gatewayFee",
          label: "Gateway Fee"
        }, {
          id: "agentId",
          label: "Agent",
          type: "select",
          options: []
        }]
      ],
    }, {
      title: "DEFAULT COMMERCIALS - PAYIN TABLE",
      id: "payinDefaultCommercials",
      form: "payinDefaultCommercialsForm",
      columns: [{
        value: "currency",
        label: "Currency",
        type: "select",
        options: "baseCurrencies",
        canBeDisabled: true
      }, {
        value: "method",
        label: "Method",
        type: "select",
        options: "paymentMethods",
        canBeDisabled: true
      }, {
        value: "traffic",
        label: "Traffic",
        type: "select",
        options: "trafficTypes",
        canBeDisabled: true
      }, {
        value: "tPlusX",
        label: "T+X",
        type: "input",
        isNumber: true,
        rightAligned: true
      }, {
        value: "payinFee",
        label: "Payin Fee",
        isNumber: true,
        type: "input",
        rightAligned: true
      }, {
        value: "settlementFee",
        label: "Settlement Fee",
        type: "input",
        isNumber: true,
        rightAligned: true
      }, {
        value: "date",
        label: "Date",
        type: "date",
        rightAligned: true,
        isFormattedDate: true,
        dateFormat: "DD.MM.YYYY"
      }, {
        value: "action",
        label: "",
        type: "button",
        rightAligned: true
      }]
    }, {
      title: "DEFAULT COMMERCIALS - PAYOUT TABLE",
      id: "payoutDefaultCommercials",
      form: "payoutDefaultCommercialsForm",
      columns: [{
        value: "currency",
        label: "Currency",
        type: "select",
        options: "baseCurrencies",
        canBeDisabled: true
      }, {
        value: "method",
        label: "Method",
        type: "select",
        options: "payoutMethods",
        canBeDisabled: true
      }, {
        value: "payoutFee",
        label: "Payout Fee",
        type: "input",
        rightAligned: true,
        isNumber: true
      }, {
        value: "date",
        label: "Date",
        type: "date",
        rightAligned: true,
        isFormattedDate: true,
        dateFormat: "DD.MM.YYYY"
      }, {
        value: "empty1",
        label: "",
        type: ""
      }, {
        value: "empty2",
        label: "",
        type: ""
      }, {
        value: "action",
        label: "",
        type: "button",
        rightAligned: true,
      }]
    }, {
      title: "COMMERCIAL SETTINGS",
      sections: [{
        id: "buys",
        tables: "clientBuyMidSettings",
        errorsMap: "buysErrorsMap",
        clickMap: "buysClickMap",
        refs: "buyRefs"
      }, {
        id: "payouts",
        tables: "clientPayoutMidSettings",
        errorsMap: "payoutsErrorsMap",
        clickMap: "payoutsClickMap",
        refs: "payoutRefs"
      }]
    }, {
      title: "TRAFFIC ESTIMATIONS",
      form: "appForm",
      rows: [
        [{
          id: "dailyTransactions",
          label: "Daily Transactions"
        }, {
          id: "dailyUsers",
          label: "Daily Users"
        }]
      ]
    }, {
      title: "APP SETTINGS",
      form: "appForm",
      rows: [
        [{
          id: "buyFeeType",
          label: "Deposit Fee",
          type: "select-input",
          value: {
            id: "buyFeeValue",
            label: ""
          },
          options: [{
            value: "Percent",
            label: "Percent"
          }, {
            value: "Flat Rate",
            label: "Units"
          }]
        }, {
          id: "sellFeeType",
          label: "Withdrawal Fee",
          type: "select-input",
          value: {
            id: "sellFeeValue",
            label: " "
          },
          options: [{
            value: "Percent",
            label: "Percent"
          }, {
            value: "Flat Rate",
            label: "Units"
          }]
        }]
      ]
    }],

    //Validation
    mainForm: new Form({
      whiteLabelId: "",
      name: "",
      shortName: "",
      baseCurrencies: [],
      email: "",
      skype: "",
      telegram: "",
      phone: "",
      login: "",
      password: "",
      agentId: "",
      url: null,
      postbackUrl: null,
      merchantUrl: null,
      applicationType: null,
      isMonolith: false,
      isSandbox: false,
      postbackApiKey: null,
      payoutPostbackUrl: null,
      apiKey: null,
      returnUrl: null,
      approvalRequestId: null,
      checkPayoutHash: false,
      payoutApiKey: "",
      payoutHashVerification: false,
      enableTwoFactorAuth: "",
      gateway: "",
      gatewayFee: ""
    }, [{
      name: "whiteLabelId",
      type: "isNumber",
      rules: {
        required: true
      }
    }, {
      name: "name",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "shortName",
      type: "isString",
      rules: {
        max: 20,
        required: false
      }
    }, {
      name: "baseCurrencies",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "email",
      type: "isEmail",
      rules: {
        required: false
      }
    }, {
      name: "skype",
      type: "isString",
      rules: {
        max: 40,
        required: false
      }
    }, {
      name: "telegram",
      type: "isString",
      rules: {
        max: 40,
        required: false
      }
    }, {
      name: "phone",
      type: "isString",
      rules: {
        max: 100,
        required: false
      }
    }, {
      name: "login",
      type: "isEmail",
      rules: {
        max: 50,
        required: true
      }
    }, {
      name: "password",
      type: "isPattern",
      rules: {
        required: true,
        pattern: PASSWORD_REGEX,
        customErrorMessage: "Password must contain at least 1 lowercase alphabetical character, 1 uppercase alphabetical character, 1 numeric character, 1 special character and must be 8 characters or longer"
      }
    }, {
      name: "url",
      type: "isUrl",
      rules: {
        required: false
      }
    }, {
      name: "merchantUrl",
      type: "isUrl",
      rules: {
        required: false
      }
    }, {
      name: "applicationType",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "isMonolith",
      type: "isBoolean",
      rules: {
        required: false
      }
    }, {
      name: "isSandbox",
      type: "isBoolean",
      rules: {
        required: false
      }
    }, {
      name: "postbackUrl",
      type: "isUrl",
      rules: {
        required: false
      }
    }, {
      name: "postbackApiKey",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "payoutPostbackUrl",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "apiKey",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "returnUrl",
      type: "isUrl",
      rules: {
        required: false
      }
    }, {
      name: "agentId",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "approvalRequestId",
      type: "isNumber",
      rules: {
        required: false
      }
    }, {
      name: "checkPayoutHash",
      type: "isBoolean",
      rules: {
        required: false
      }
    }, {
      name: "payoutApiKey",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "payoutHashVerification",
      type: "isBoolean",
      rules: {
        required: false
      }
    }, {
      name: "enableTwoFactorAuth",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "gateway",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "gatewayFee",
      type: "isString",
      rules: {
        required: false
      }
    }
    ]),

    appForm: new Form({
      buyFeeType: "Percent",
      buyFeeValue: 0,
      sellFeeType: "Percent",
      sellFeeValue: 0,
      dailyTransactions: "",
      dailyUsers: ""
    }, [{
      name: "buyFeeType",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "buyFeeValue",
      type: "isNumber",
      rules: {
        required: false,
        min: 0,
        max: 100
      }
    }, {
      name: "sellFeeType",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "sellFeeValue",
      type: "isNumber",
      rules: {
        required: false,
        min: 0,
        max: 100
      }
    }, {
      name: "dailyTransactions",
      type: "isNumber",
      rules: {
        min: 0,
        required: false
      }
    }, {
      name: "dailyUsers",
      type: "isNumber",
      rules: {
        min: 0,
        required: false
      }
    }]),
    
    baseCurrencies: [],
    paymentMethods: [],
    payoutMethods: [],
    trafficTypes: [],
    lookups: [],
    companies: [],
    agents: [],
    applicationTypes: [],
    twoFAOptions: twoFAOptions,
    gateways: [],
    companyId: "",

    buysErrorsMap: {},
    buysClickMap: {},
    clientBuyMidSettings: [],

    payoutsErrorsMap: {},
    payoutsClickMap: {},
    clientPayoutMidSettings: [],

    isFeeValueError: false,

    baseData: [],

    confirmPassword: "",

    showAlert: false,
    alertType: "success",
    alertMessage: "",

    showModal: false,
    modalTitle: "Api Keys Details",
    isMerchantApiKeyReset: !this.props.viewOrEdit,
    isSecretKeyReset: !this.props.viewOrEdit,
    isPostbackApiKeyVisible: false,
    companyMonolithUrl: "",
    companySandboxUrl: "",

    loadingGeneralSettings: true,
    confirmPasswordTouched: false,
    submitTouched: false,
    isVisiblePassword: false,
    merchantSettingsV2: false,
    isSubmittingCommercials: false,

    addTiers :"",
    access: [],
    roleId: "",
    tiersValue :[],
    processingFeeRowDTOList: [],
    selectedItemsList:[],
    temporaryProcessingFeeRowDTOListItem: {},
    savedData: {},
    tableId: "",
    pspPosition: "",
    inputErrorsMap: [],
    checkButtonCLick: false,
    logs: []
  };

  subscribeFunction = null;
  buyRefs = {};
  payoutRefs = {};

  componentDidMount() {
    const clientId = this.props.clientId;
    const routeState = this.props.location && this.props.location.state;
    const processingFeeRowDTOArray=[];
    let approvalRequestId;
    if (routeState) {
      approvalRequestId = routeState.params.approvalRequestId;
    }

    const { mainForm, appForm } = this.state;
    const { viewOrEdit } = this.props;

    const storeState = store.default.getState().authReducer;
    if (storeState.access && storeState.roleId) {
      const roleId = storeState.roleId;
      const mainFieldRules = mainForm.fieldRules;

      mainFieldRules.forEach(fieldRule => {
        const fieldRuleName = fieldRule.name;

        if (["url", "postbackApiKey"].some(elem => elem === fieldRuleName)) {
          fieldRule.rules.required = roleId === "MASTER_TECH";
        } else if (fieldRuleName === "password") {
          fieldRule.rules.required = viewOrEdit !== 2;
        }
      });

      this.setState({
        access: storeState.access,
        roleId: storeState.roleId,
        mainForm: Object.assign(mainForm, { fieldRules: mainFieldRules })
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        const roleId = state.roleId;
        const mainFieldRules = mainForm.fieldRules;
        mainFieldRules.forEach(fieldRule => {
          const fieldRuleName = fieldRule.name;

          if (["url", "postbackApiKey"].some(elem => elem === fieldRuleName)) {
            fieldRule.rules.required = roleId === "MASTER_TECH";
          } else if (fieldRuleName === "password") {
            fieldRule.rules.required = viewOrEdit !== 2;
          }
        });

        this.setState({
          access: state.access,
          roleId: roleId,
          mainForm: Object.assign(mainForm, { fieldRules: mainFieldRules })
        });
      }
    });

    if (approvalRequestId) {
      crudActions.get(`v1/approval/requests/${approvalRequestId}`).then(
        (requestData) => {
          if (requestData) {
            this.setState({
              mainForm: Object.assign(this.state.mainForm, {
                phone: requestData.phone,
                email: requestData.email,
                login: requestData.email,
                telegram: requestData.telegram,
                agentId: requestData.referredAgentId,
                skype: requestData.skype
              })
            });
          }
        }
      );
    }

    Promise.all([
      crudActions.get('v1/adminpanel/lookups'),
      crudActions.get(`v1/companies/labels`),
      crudActions.get('v1/psp'),
      crudActions.get('v1/agent/all')]).then(
      (data) => {
        const lookups = data[0];
        const companies = data[1];
        const psps = data[2];
        const agents = data[3];
        agents.unshift({
          value: "0",
          label: "No agent"
        });
        this.setState({
          lookups: Object.assign(lookups, { psp: psps ? psps.map(elem => {
            return {
              value: elem.id,
              label: elem.name
            };
          }) : []}),
          companies: companies || [],
          mainForm: Object.assign(mainForm, {
            whiteLabelId: companies && companies.length && !clientId ? companies[0].value : "",
            ...(!viewOrEdit && { checkPayoutHash: true }),
          }),
          agents: agents || [],
          applicationTypes: lookups.applicationTypes || [],
          companyId:  companies && companies.length && !clientId ? companies[0].value : ""
        });
      } 
    ).then(
      () => {
        (clientId ? Promise.all([
          crudActions.get(`v1/clients/${clientId}`),
          crudActions.get(`v1/default-commercials/${clientId}`)
        ]) : Promise.resolve()).then(
          (data) => {
            const clientData =  data && data[0];
            const defaultCommercials = data && data[1];
            if (clientData) {
              const newAppForm = Object.assign({}, clientData.clientAppSettings);
              delete clientData.clientAppSettings;

              const fillMaps = (settings, map, isClickMap) => {
                settings.forEach(
                  pspTable => {
                    map[pspTable.type] = {};
                    pspTable.merchantSettingsRows.forEach(
                      row => {
                        map[pspTable.type][row.id] = {};
                        pspTable.fields.forEach(
                          column => {
                            map[pspTable.type][row.id][column.name] = isClickMap ? !(!row[column.name] && row[column.name] !== 0) : false;
                          }
                        );
                      }
                    );
                  }
                );

                return map;
              };
                  
              const clientBuyMidSettings = clientData.clientBuyMidSettings.map(x => x);
              clientBuyMidSettings.forEach(elem =>
                elem.merchantSettingsRows.forEach(element => {
                  element.processingFeeRowDTOList.forEach(e =>
                    processingFeeRowDTOArray.push(e));
                }
                ));
              this.setState({
                processingFeeRowDTOList: processingFeeRowDTOArray
              });

              this.setState({
                processingFeeRowDTOList: processingFeeRowDTOArray
              });

              const clientPayoutMidSettings = clientData.clientPayoutMidSettings.map(x => x);

              clientPayoutMidSettings.forEach(elem =>
                elem.merchantSettingsRows.forEach(element => {
                  element.processingFeeRowDTOList.forEach(e =>
                    processingFeeRowDTOArray.push(e));
                }));

              const payinDefaultCommercials = defaultCommercials.payinDefaultCommercials;
              const payoutDefaultCommercials = defaultCommercials.payoutDefaultCommercials;

              delete clientData.clientBuyMidSettings;
              delete clientData.clientPayoutMidSettings;

              const mainFieldRules = mainForm.fieldRules;
              const appFieldRules = appForm.fieldRules;

              if (newAppForm.sellFeeType === "Flat Rate") {
                const sellFeeTypeField = appFieldRules.find(rule => rule.name === "sellFeeValue");
                delete sellFeeTypeField.rules.max;
              }

              if (newAppForm.buyFeeType === "Flat Rate") {
                const buyFeeTypeField = appFieldRules.find(rule => rule.name === "buyFeeValue");
                delete buyFeeTypeField.rules.max;
              }

              if (!clientData.agentId) {
                clientData.agentId = "0";
              } else {
                clientData.agentId = clientData.agentId.toString();
              }

              this.setState({
                clientBuyMidSettings,
                buysClickMap: fillMaps(clientBuyMidSettings, {}, true),
                buysErrorsMap: fillMaps(clientBuyMidSettings, {}, false),
                clientPayoutMidSettings,
                payoutsClickMap: fillMaps(clientPayoutMidSettings, {}, true),
                payoutsErrorsMap: fillMaps(clientPayoutMidSettings, {}, false),
                payinDefaultCommercials: payinDefaultCommercials,
                payoutDefaultCommercials: payoutDefaultCommercials,

                appForm: Object.assign(appForm, newAppForm, { fieldRules: appFieldRules }),
                mainForm: Object.assign(mainForm, clientData, {
                  fieldRules: mainFieldRules,
                  gatewayFee: clientData.gatewayFee !== null ? clientData.gatewayFee.toString() : ""
                }),
                merchantSettingsV2: !!clientData.merchantSettingsV2
              });
            }

            this.loadGeneralData(
              clientData ? clientData.whiteLabelId : mainForm.whiteLabelId, clientData ? clientData.baseCurrencies : ""
            );
          }
        );
      }
    ).catch(
      err => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message
          });
        }
      }
    );
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  loadGeneralData = (whiteLabelId, baseCurrencies) => {
    const { clientId } = this.props;
    const { mainForm, panels, roleId } = this.state;

    this.setState({
      loadingGeneralSettings: true
    });

    Promise.all([
      crudActions.get(`v1/adminpanel/company/${whiteLabelId}`),
      crudActions.get(`v1/companies/url/${whiteLabelId}`)
    ])
      .then(
        (response) => {
          const data = response[0];
          const companyData = response[1];

          const mainPanel = panels.find(panel => panel.form === "mainForm").rows[0];
          const urlField = mainPanel.find(item => item.id === "url");
          const apiKeyField = mainPanel.find(item => item && item.id === "apiKey");
          urlField.disabled = mainForm.isMonolith && !!companyData.monolithUrl;
          if (apiKeyField) {
            apiKeyField.disabled = mainForm.isMonolith && !!companyData.monolithUrl;
          }

          if (mainForm.isMonolith && !companyData.monolithUrl) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: "Please set monolith URL in WL settings first."
            });
          }

          const updateObject = {
            loadingGeneralSettings: false,
            companyMonolithUrl: companyData.monolithUrl,
            companySandboxUrl: companyData.sandboxUrl,
            ...(mainForm.isMonolith && {
              mainForm: Object.assign(mainForm, {
                ...(!companyData.monolithUrl && { isMonolith: false }), 
                url: companyData.monolithUrl
              })
            }),
            ...(mainForm.isSandbox && {
              mainForm: Object.assign(mainForm, {
                ...(!companyData.sandboxUrl && { isSandbox: false }), 
                url: companyData.sandboxUrl
              })
            })
          };

          if (data) {
            data.storedLookups.trafficTypes = ["FTD", "TRUSTED"];
            updateObject.baseCurrencies = data.storedLookups.baseCurrencies.map(elem => this.mapItems(elem, 'currency'));
            updateObject.paymentMethods = data.storedLookups.paymentMethods.map(elem => ({ label: elem, value: elem }));
            updateObject.payoutMethods = data.storedLookups.payoutMethods.map(elem => ({ label: elem, value: elem }));
            updateObject.trafficTypes = data.storedLookups.trafficTypes.map(elem => ({ label: elem, value: elem }));
            updateObject.gateways = data.storedLookups.gateways.map(elem => ({
              label: elem,
              value: elem
            }));
            updateObject.gateways.unshift({
              label: "None (default)",
              value: "DEFAULT"
            });
          }

          if (!clientId) {
            updateObject.mainForm = Object.assign(this.state.mainForm, {
              baseCurrencies: data.storedLookups.baseCurrencies,
              gateway: "DEFAULT",
              applicationType: "APM"
            });
            this.generateMerchantPassword();
            this.onResetApiKey("isMerchantApiKeyReset", "postbackApiKey");
            this.onResetApiKey("isSecretKeyReset", "payoutApiKey");
          } else {
            if (!updateObject.baseCurrencies.filter(elem => baseCurrencies.some(curr => curr === elem.value)).length) {
              updateObject.baseCurrencies = updateObject.baseCurrencies.concat(baseCurrencies.map(
                elem => {
                  return this.mapItems(elem, "currency");
                }
              ));
            }
          }

          this.setState(updateObject, () => {
            if (!clientId && roleId === "WL_ADMIN") {
              this.onValueChange({ value: "on" }, "isMonolith", "mainForm");
            }
          });
        }
      );
  };

  mapItems = (fieldValue, arrayName) => {
    if (!arrayName) {
      return {
        label: fieldValue
      };
    }

    const { lookups } = this.state;
    const foundArray = lookups[arrayName];

    if (!foundArray) {
      return {
        label: ""
      };
    }

    const item = foundArray.find(elem => elem.value === fieldValue);

    if (!item) {
      return {
        label: ""
      };
    }

    return item;
  };

  onValueChange = (event, fieldName, formName, id) => {
    let value = event.target ? event.target.value : event.value || event.map(elem => elem.value);
    const { clientId } = this.props;
  	const { mainForm, submitTouched, companyMonolithUrl, companySandboxUrl, panels } = this.state;
  	const trimmedFields = ["login", "password", "shortName"];
    if (trimmedFields.some(trimmedField => trimmedField === fieldName)) {
      value = value.trim();
    }

    const originalForm = this.state[formName];
    const form = typeof id !== 'number' ? originalForm : originalForm[id];
    const prevValue = form[fieldName];
    const fieldRules = form.fieldRules;
    const editedRule = fieldRules.find(rule => rule.name === fieldName);
    if (editedRule.type === "isNumber" && value) {
      value = parseFloat(value);
    }
    if (editedRule.type === "isBoolean") {
      value = !form[fieldName];

      if (fieldName === "checkPayoutHash" && !value) {
        form.payoutApiKey = "";
        form.payoutHashVerification = false;
      }
    }

    if (clientId) {
      this.addLog(prevValue, fieldName !== "payoutApiKey" ? value : "*".repeat(value.length), fieldName, formName, clientId);
    }

    if (fieldName === "sellFeeType") {
      const fieldRule = fieldRules.find(rule => rule.name === "sellFeeValue");
      if (value === "Flat Rate") {
        delete fieldRule.rules.max;
      } else if (value === "Percent") {
        fieldRule.rules.max = 100;
      }
    } else if (fieldName === "buyFeeType") {
      const fieldRule = fieldRules.find(rule => rule.name === "buyFeeValue");
      if (value === "Flat Rate") {
        delete fieldRule.rules.max;
      } else if (value === "Percent") {
        fieldRule.rules.max = 100;
      }
    } else if (fieldName === "whiteLabelId") {
      this.loadGeneralData(value, mainForm.baseCurrency);
    } else if (fieldName === "enableTwoFactorAuth") {
      this.on2FASettingsChange(event.value);
      return;
    } else if (fieldName === "isMonolith") {
      const mainPanel = panels.find(panel => panel.form === "mainForm").rows[0];
      const urlField = mainPanel.find(item => item.id === "url");
      const apiKeyField = mainPanel.find(item => item.id === "apiKey");
      const fieldRule = fieldRules.find(rule => rule.name === "apiKey");

      if (value && !companyMonolithUrl) {
        this.setState({
          showAlert: true,
          alertType: "error",
          alertMessage: "Please set monolith URL in WL settings first."
        });
        value = !value;
      }

      urlField.disabled = value && !!companyMonolithUrl;
      apiKeyField.disabled = value && !!companyMonolithUrl;
      fieldRule.rules.required = !value;
      form.url = value ? companyMonolithUrl : "";
      form.isSandbox = !value;
      form.apiKey = "";
    } else if (fieldName === "isSandbox") {
      const mainPanel = panels.find(panel => panel.form === "mainForm").rows[0];
      const urlField = mainPanel.find(item => item.id === "url");

      if (value && !companySandboxUrl) {
        this.setState({
          showAlert: true,
          alertType: "error",
          alertMessage: "Please set sandbox URL in WL settings first."
        });
        value = !value;
      }

      urlField.disabled = value && !!companySandboxUrl;
      form.url = value ? companySandboxUrl : "";
      if (form.isMonolith && !value) {
        urlField.disabled = !!companyMonolithUrl;
        form.url = companyMonolithUrl || "";
      }
    }

    form[fieldName] = value;
    form.fieldRules = fieldRules;

    if (submitTouched) {
      form.isFormValid();
    }

    this.setState({
      [formName]: originalForm
    });
  };

  addLog = (valueBefore, valueAfter, field, form, id) => {
    const { logs } = this.state;

    const type = form === "mainForm" ? "MERCHANT_UPDATE" : (form === "clientBuyMidSettings" ? "MERCHANT_UPDATE_DEPOSIT" : "MERCHANT_UPDATE_PAYOUT");

    const updatedLogs = addLogItem(logs, type, valueBefore, valueAfter, field, id);

    this.setState({
      logs: updatedLogs
    });

    this.setState({
      logs
    });
  };

  onChangeGeneralSettings = (event, column, elementId, tableType, sectionTables) => {
    const errorsMap = {
      "clientBuyMidSettings": "buysErrorsMap",
      "clientPayoutMidSettings": "payoutsErrorsMap"
    };
    const currentErrorsMap = this.state[errorsMap[sectionTables]];
    const tables = this.state[sectionTables];
    const foundTable = tables.find(elem => elem.type === tableType);
    if (!foundTable) {
      return;
    }

    const foundRow = foundTable.merchantSettingsRows.find(elem => elem.id === elementId);
    const currentColumn = foundTable.fields.find(header => header.name === column);
    const prevValue = foundRow.values[column];
    const value = parseFloat(event.target.value);
    foundRow.values[column] = value;

    if (currentColumn.isPercent) {
      currentErrorsMap[tableType][elementId][column] = value < 0 || value > 100;
    } else if (currentColumn.name === "MIN_AMOUNT") {
      currentErrorsMap[tableType][elementId][column] = value > foundRow.values.MAX_AMOUNT || value < 0 || (!value && value !== 0);
    } else if (currentColumn.name === "MAX_AMOUNT") {
      currentErrorsMap[tableType][elementId][column] = value < foundRow.values.MIN_AMOUNT || value < 0 || (!value && value !== 0);
    } else {
      currentErrorsMap[tableType][elementId][column] = value < 0 || (!value && value !== 0);
    }

    if (this.props.clientId) {
      this.addLog(prevValue, value, column, sectionTables, foundRow.midSettingsId);
    }

    this.setState({
      [sectionTables]: tables,
      [errorsMap[sectionTables]]: currentErrorsMap
    });
  };

  isFieldDisabled = (fieldName) => {
    const { mainForm } = this.state;
    const { viewOrEdit } = this.props;
    const storeState = store.default.getState().authReducer;

    if (fieldName === "isMonolith" && (viewOrEdit || storeState.roleId === "WL_ADMIN")) {
      return true;
    }

    if (fieldName === "checkPayoutHash" && !viewOrEdit) {
      return true;
    }

    if (!viewOrEdit) {
      return false;
    } 
    
    if((storeState.roleId !== "MASTER_TECH" && storeState.roleId !== "WL_ADMIN") && fieldName === "agentId"){
      return true;
    }

    if (fieldName === "enableTwoFactorAuth" && mainForm.lockedTwoFactorAuth) {
      return true;
    }

    return viewOrEdit === 1 ? true : fieldName === "whiteLabelId";
  };

  checkIfEditAllowed = (row, columnName) => {
    const { merchantSettingsV2 } = this.state;

    // Allow edit settings for SANDBOX provider
    if (row.pspId === 57) return true;

    if (!merchantSettingsV2) return true;

    const value =  this.getValue(row.values[columnName]);
    if (value) {
      return row.nonZeroValuesUpdateAllowed;
    }
    return row.zeroValuesUpdateAllowed;
  };

  arePasswordsIdentical = () => {
    const { mainForm, confirmPassword } = this.state;
    const { viewOrEdit } = this.props;

    if (viewOrEdit) {
      return true;
    }

    if (!confirmPassword) {
      return false;
    }

    return mainForm.password === confirmPassword;
  };

  onConfirmPasswordChange = (event) => {
    this.setState({
      confirmPassword: event.target.value,
      confirmPasswordTouched: true
    });
  };

  submitClientSettings = (e) => {
    e.preventDefault();
    const clientId = this.props.clientId;
    const routeState = this.props.location && this.props.location.state;
    let approvalRequestId;
    if (routeState) {
      approvalRequestId = routeState.params.approvalRequestId;
    }

    const { mainForm, appForm, buysClickMap, payoutsClickMap, clientBuyMidSettings, clientPayoutMidSettings,
      buysErrorsMap, payoutsErrorsMap, selectedItemsList, logs } = this.state;
    const isMainFormValid = mainForm.isFormValid();
    const isAppFormValid = appForm.isFormValid();

    const checkGeneralSettings = (settingsTables, settingsErrorsMap, settingsClickMap) => {
      let isErrors = false;
      let isFeeError = false;
      settingsTables.forEach(
        pspTable => {
          pspTable.merchantSettingsRows.forEach(
            row => {
              pspTable.fields.forEach(
                column => {
                  const columnValue = row.values[column.name];

                  if (column.isPercent) {
                    let dynamicCondition = columnValue < 0;
                    // Manually exclude TEST provider from validation
                    const feesCondition = row.pspId !== 57 && (column.name === "PROCESSING_FEE" || column.name === "PAYOUT_PROCESSING_FEE");
                    if (feesCondition) {
                      dynamicCondition = columnValue <= 0;
                    }
                    settingsErrorsMap[pspTable.type][row.id][column.name] = dynamicCondition || columnValue > 100 || (!columnValue && columnValue !== 0);
                    if (feesCondition && settingsErrorsMap[pspTable.type][row.id][column.name]) {
                      isFeeError = true;
                    }
                  } else if (column.name === "MIN_AMOUNT") {
                    settingsErrorsMap[pspTable.type][row.id][column.name] = columnValue > row.values.MAX_AMOUNT || columnValue < 0 || (!columnValue && columnValue !== 0);
                  } else if (column.name === "MAX_AMOUNT") {
                    settingsErrorsMap[pspTable.type][row.id][column.name] = columnValue < row.values.MIN_AMOUNT || columnValue < 0 || (!columnValue && columnValue !== 0);
                  } else {
                    settingsErrorsMap[pspTable.type][row.id][column.name] = columnValue < 0 || (!columnValue && columnValue !== 0);
                  }

                  if (settingsErrorsMap[pspTable.type][row.id][column.name]) {
                    isErrors = true;
                    settingsClickMap[pspTable.type][row.id][column.name] = true;
                    // Manually remove "0" from error input
                    if (columnValue === 0) {
                      row.values[column.name] = "";
                    }
                  }
                }
              );
            }
          );
        }
      );

      return [isErrors, isFeeError];
    };

    const checkValidation = (selectedItems) => {
      let isValid = true;
      const { inputErrorsMap } = this.state;
      let elemId = '';
      let indexofElem = '';
      selectedItems.forEach(item => {
        elemId = inputErrorsMap.find(elem => elem.id === item.id);
        indexofElem = inputErrorsMap.indexOf(elemId);

        if(inputErrorsMap){
          if(!item.toAmount ||item.toAmount < item.fromAmount || item.toAmount === item.fromAmount ){
            inputErrorsMap[indexofElem].row.set('toAmount', false);
          }

          if(isNaN(item.fromAmount) || item.toAmount < item.fromAmount || item.toAmount === item.fromAmount ){
            inputErrorsMap[indexofElem].row.set('fromAmount', false);
          }

          item['values'] = item['values'] instanceof Map ? item['values'] : new Map(Object.entries(item['values']));

          item['values'].forEach((value, key) => {
            if (isNaN(value)) {
              inputErrorsMap[indexofElem].row.set(key, false);
            }
            if (inputErrorsMap[indexofElem].row.get(key) === false) {
              isValid = false;
            }
          });

          if (inputErrorsMap[indexofElem].row.get('toAmount') === false ||
            inputErrorsMap[indexofElem].row.get('fromAmount') === false) {
            isValid = false;
          }
        }
      });

      this.setState({
        inputErrorsMap: inputErrorsMap,
        checkButtonCLick: !isValid
      });

      return isValid;
    };
    //check general settings
    const [isBuysErrors, isFeeErrors] = checkGeneralSettings(clientBuyMidSettings, buysErrorsMap, buysClickMap);
    const [isPayoutsErrors, isPayoutFeeErrors] = checkGeneralSettings(clientPayoutMidSettings, payoutsErrorsMap, payoutsClickMap);
    const isTiersInputErrors = checkValidation(selectedItemsList);
    const isFeeValuesErrors = isFeeErrors || isPayoutFeeErrors;

    this.setState({
      confirmPasswordTouched: true,
      submitTouched: true,
      buysErrorsMap: buysErrorsMap,
      payoutsErrorsMap: payoutsErrorsMap,
      isFeeValueError: isFeeValuesErrors,
      buysClickMap: buysClickMap,
      payoutsClickMap: payoutsClickMap
    });

    if (mainForm.errors.has('baseCurrencies')) {
      this.setState({
        showAlert: true,
        alertType: "error",
        alertMessage: 'Please, add base currencies in according admin panel.'
      });

      return false;
    }

    if (mainForm.errors.has("postbackApiKey")) {
      this.setState({
        showModal: true
      });
    }

    selectedItemsList.forEach(elem => {
      if(typeof elem.id === 'string' && elem.id.includes("fakeId")){
        delete elem.id;
      }
    });

    let merchantIndex = '';
    let merchantSettingsRowIndex = '';
    if(selectedItemsList) {
      clientBuyMidSettings.forEach(
        elem => elem.merchantSettingsRows.forEach(
          element => {
            const arr = [];
            merchantIndex = clientBuyMidSettings.indexOf(elem);
            merchantSettingsRowIndex = elem.merchantSettingsRows.indexOf(element);
            if (clientBuyMidSettings[merchantIndex]) {
              clientBuyMidSettings[merchantIndex]['merchantSettingsRows'][merchantSettingsRowIndex]['processingFeeRowDTOList'] = [];
            }
            selectedItemsList.forEach(
              el => {
                if(element.id === el.clientMidSettingsId){
                  merchantIndex = clientBuyMidSettings.indexOf(elem);
                  merchantSettingsRowIndex = elem.merchantSettingsRows.indexOf(element);
                  if(el.values instanceof Map){
                    const arrObjects = Object.fromEntries(el.values);
                    el.values = Object.assign({},arrObjects);
                  }
                  if(Array.isArray(el)){
                    el = Object.assign({}, el);
                  }
                  arr.push(el);
                  clientBuyMidSettings[merchantIndex]['merchantSettingsRows'][merchantSettingsRowIndex]['processingFeeRowDTOList'] = arr;
                }
              }
            );

            clientPayoutMidSettings.forEach(
              // eslint-disable-next-line no-shadow
              elem => elem.merchantSettingsRows.forEach(
                // eslint-disable-next-line no-shadow
                element => {
                  // eslint-disable-next-line no-shadow
                  const arr = [];
                  merchantIndex = clientPayoutMidSettings.indexOf(elem);
                  merchantSettingsRowIndex = elem.merchantSettingsRows.indexOf(element);
                  if (clientPayoutMidSettings[merchantIndex]) {
                    clientPayoutMidSettings[merchantIndex]['merchantSettingsRows'][merchantSettingsRowIndex]['processingFeeRowDTOList'] = [];
                  }
                  selectedItemsList.forEach(
                    el => {
                      if(element.id === el.clientMidSettingsId){
                        if(el.values instanceof Map){
                          const arrObjects = Object.fromEntries(el.values);
                          el.values = Object.assign({},arrObjects);
                        }
                        if(Array.isArray(el)){
                          el = Object.assign({}, el);
                        }
                        arr.push(el);
                        clientPayoutMidSettings[merchantIndex]['merchantSettingsRows'][merchantSettingsRowIndex]['processingFeeRowDTOList'] = arr;
                      }
                    }
                  );
                }
              )
            ); 
      
          }
        )
      );
    }
    if (isFeeValuesErrors) {
      this.setState({
        showAlert: true,
        alertType: "error",
        alertMessage: "Mid is set to 0% payin fee or 0% payout fee. Please update the merchant settings and try again."
      });
      return;
    }
    if (!isBuysErrors && !isPayoutsErrors && isMainFormValid &&
      isAppFormValid && isTiersInputErrors && this.arePasswordsIdentical()) {
      this.setState({
        isSubmitting: true
      });
      const mainFormData = mainForm.data();
      if (mainFormData.agentId === "0") {
        mainFormData.agentId = "";
      }
      (clientId ? crudActions.put : crudActions.post)(`v1/clients`, Object.assign(mainFormData, {
        clientBuyMidSettings,
        clientPayoutMidSettings,
        clientAppSettings: Object.assign(appForm.data(), {
          buyFeeValue: appForm.buyFeeValue || 0,
          sellFeeValue: appForm.sellFeeValue || 0
        }),
        id: clientId,
        ...(clientId && { companyId: mainFormData.whiteLabelId }),
        ...(clientId && { changes: logs.filter(log => log.parameters.length) }),
        approvalRequestId: mainForm.approvalRequestId || approvalRequestId
      })).then(
        () => {
          this.setState({
            showAlert: true,
            alertType: "success",
            alertMessage: "",
            changes: [],
            isSubmitting: false
          });
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isSubmitting: false
            });
          }
        }
      );
    } else {
      this.setState({
        showAlert: true,
        alertType: "error",
        alertMessage: "Values in some fields are invalid. Please, fix them to continue."
      });
    }
  };

  getValue = (value) => {
    if (value === 0) {
      return 0;
    } else if (!value) {
      return "";
    }

    return value;
  };

  getSelectOptions = (column) => {
    if (column.id === "agentId") {
      return this.getFilteredAgents();
    }
    return this.state[column.options];
  };

  getFilteredAgents = () => {
    const { agents, mainForm } = this.state;

    return agents.filter(agent => agent.companyId === mainForm.whiteLabelId || agent.value === "0");
  };

  filterPanelRows = (panel) => {
    const { roleId } = this.state;

    if (roleId !== "MASTER_TECH" && roleId !== "WL_ADMIN" && roleId !== "WL_USER" &&
      roleId !== "MASTER_ADMIN" && roleId !== "MASTER_USER" ) {
      return [panel.rows[0]].concat(panel.rows.slice(2));
    }

    return panel.rows;
  };

  mapSelectedItems = (formName, fieldName, arrayName) => {
    const field = this.state[formName][fieldName];
    const lookup = this.state.lookups[arrayName];

    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  onTableDataClick = (id, clickMapType, fieldType, tableType, isDoubleClick) => {
    const { viewOrEdit } = this.props;
    if (viewOrEdit === 1) {
      return false;
    }

    const valuesMap = {
      "buysClickMap": "clientBuyMidSettings",
      "payoutsClickMap": "clientPayoutMidSettings"
    };

    const refMap = {
      "buysClickMap": "buyRefs",
      "payoutsClickMap": "payoutRefs"
    };

    const errorsMap = {
      "buysClickMap": "buysErrorsMap",
      "payoutsClickMap": "payoutsErrorsMap"
    };

    const valueTables = valuesMap[clickMapType];
    const refType = refMap[clickMapType];
    const errors = errorsMap[clickMapType];

    const valueTable = this.state[valueTables].find(elem => elem.type === tableType);
    const errorsValue = this.state[errors][tableType];
    const valueRow = valueTable.merchantSettingsRows.find(elem => elem.id === id);
    if (!valueRow || !valueRow.values || (!valueRow.values[fieldType] && valueRow.values[fieldType] !== 0) ||
        (errorsValue && errorsValue[id][fieldType])) {
      return false;
    }

    const clickMap = this.state[clickMapType];
    clickMap[tableType][id][fieldType] = !clickMap[tableType][id][fieldType];
    if (valueRow.values[fieldType] === 0 && isDoubleClick) {
      valueRow.values[fieldType] = null;
    }
    this.setState({
      [clickMapType]: clickMap
    }, () => {
      const input = this[refType][tableType][id][fieldType];
      if (input) {
        input.focus();
      }
    });
  };

  setRef = (id, fieldValue, ref, tableType, refsType) => {
    if (!this[refsType][tableType]) {
      this[refsType][tableType] = {};
    }

    if (!this[refsType][tableType][id]) {
      this[refsType][tableType][id] = {};
    }

    this[refsType][tableType][id][fieldValue] = ref;
  };

  onKeyDown = (event, id, clickMapType, fieldType, tableType) => {
    const keyPressed = event.keyCode;
    if (keyPressed === 13) {
      this.onTableDataClick(id, clickMapType, fieldType, tableType);
    }
  };

  filterPanels = () => {
    const { merchantSettingsV2, panels } = this.state;
    const { viewOrEdit } = this.props;

    const hidePanels = ["TRAFFIC ESTIMATIONS", "APP SETTINGS"];
    let filteredPanels = panels.filter(panel => hidePanels.indexOf(panel.title) < 0);

    if (!viewOrEdit) {
      filteredPanels = filteredPanels.filter(panel => panel.title !== "COMMERCIAL SETTINGS" && panel.id !== "payinDefaultCommercials" && panel.id !== "payoutDefaultCommercials");
    }

    if (!merchantSettingsV2) {
      filteredPanels = filteredPanels.filter(panel => panel.id !== "payinDefaultCommercials" && panel.id !== "payoutDefaultCommercials");
    }
    return filteredPanels;
  };

  addTiersButton = (fullElem, buttonId, rowId, tableElem)=>{
    this.setState({
      addTiers: fullElem.id,
      tableId: rowId,
      pspPosition : tableElem.type
    });
  };

  onTypesTemporaryChange = (temporaryItem) => {
    this.setState({
      temporaryProcessingFeeRowDTOListItem: temporaryItem
    });
  };

  onTypesChange = (selectedItems, inputErrorsMap) => {
    if (selectedItems) {
      this.setState({
        selectedItemsList: selectedItems,
        inputErrorsMap: inputErrorsMap,
        checkButtonCLick: false
      });
    }
    
  };

  onTogglerClick = (value, type, elementId) => {
    const tables = this.state[type];
    const findElem = tables
      .find(elem=> elem['merchantSettingsRows']
        .find(el =>{ return el.id === elementId; }))['merchantSettingsRows'].find(el =>{ return el.id === elementId; });
      
    this.setState({
      clickHiddenButton: value
    });

    findElem['hideButton'] = !value;
    this.setState({
      [type]: tables
    });
  };

  togglePasswordView = (e) => {
    e.preventDefault();
    this.setState({
      isVisiblePassword: !this.state.isVisiblePassword
    });
  };

  generateMerchantPassword = () => {
    const password = generatePassword();

    this.setState({
      mainForm: Object.assign(this.state.mainForm, {
        password: password
      }),
      confirmPassword: password,
      confirmPasswordTouched: true
    });
  };

  on2FASettingsChange = (value) => {
    const { mainForm } = this.state;
    const { clientId } = this.props;

    (clientId ? crudActions.post(`v1/clients/${clientId}/two-factor`, { enableTwoFactorAuth: value }) : Promise.resolve())
      .then(response => {
        this.setState({
          mainForm: Object.assign(mainForm, {
            enableTwoFactorAuth: value
          }),
          ...(clientId && { showAlert: true }),
          ...(clientId && { alertType: "success" }),
          ...(clientId && { alertMessage: "2FA settings changed successfully." })
        });
      })
      .catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }
        }
      );
  };

  onResetClick = () => {
    const { clientId } = this.props;

    (clientId ? crudActions.patch(`v1/clients/${clientId}`) : Promise.resolve())
      .then(() => {
        this.setState({
          ...(clientId && { showAlert: true }),
          ...(clientId && { alertType: "success" }),
          ...(clientId && { alertMessage: "Operation successful." })
        });
      })
      .catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }
        }
      );
  };

  onButtonClick = (type) => {
    const { mainForm } = this.state;
    if (type === "resetQRSettings") {
      this.onResetClick();
    } else if (type === "apiKeyDetails") {
      this.setState({
        showModal: true,
        isMerchantApiKeyReset: !mainForm.postbackApiKey || !this.props.viewOrEdit,
        isSecretKeyReset: !mainForm.payoutApiKey || !this.props.viewOrEdit
      });
    }
  };

  onResetApiKey = (resetFieldId, fieldId) => {
    const { mainForm } = this.state;
    const { clientId } = this.props;

    const prevValue = mainForm[fieldId];
    const value = generateApiKey(30);

    if (clientId) {
      this.addLog(prevValue, fieldId !== "payoutApiKey" ? value : "*".repeat(30), fieldId, "mainForm", clientId);
    }

    this.setState({
      [resetFieldId]: true,
      mainForm: Object.assign(mainForm, {
        [fieldId]: value
      })
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false
    });
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  render() {
    const {
      access,
      addTiers,
      alertMessage,
      alertType,
      baseCurrencies,
      checkButtonCLick,
      confirmPassword,
      confirmPasswordTouched,
      isPostbackApiKeyVisible,
      isMerchantApiKeyReset,
      isSecretKeyReset,
      isSubmitting,
      isSubmittingCommercials,
      inputErrorsMap,
      isVisiblePassword,
      loadingGeneralSettings,
      modalTitle,
      merchantSettingsV2,
      processingFeeRowDTOList,
      roleId,
      showAlert,
      showModal,
    } = this.state;

    const foundPermission = access.find(elem => elem.permission === "HIDE_PRICING_ROW");
    
    const state = this.state;
    const { viewOrEdit } = this.props;

    const fakeId = "fakeId1";
    processingFeeRowDTOList.forEach( el => {
      if (el.id === undefined) {
        let generatedFakeId = parseInt(fakeId.replace(/[A-Za-z]/g, ""));
        ++generatedFakeId;
        generatedFakeId = "fakeId" + generatedFakeId;
        el['id'] = generatedFakeId;
      }
    });

    return <Row flexGrow={ 1 } className="module apidata editClient" vertical='start'>
      <Column flexGrow={ 1 } className='overflow-x-auto'>
        <PageHeader
          title={ !viewOrEdit ? 'Add Merchant' : viewOrEdit === 1 ? 'View Merchant' : 'Edit Merchant' }
          lastUpdate={ state.lastUpdate }
          img={ MerchantsIcon }
        />
        <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
          <Column flexGrow={ 1 } vertical='start' className="panel-block">
            {this.filterPanels().map((panel, i) => {
              return (panel.title === "COMMERCIAL SETTINGS" && loadingGeneralSettings) ? (
                <Panel key={ panel.title }>
                  <Panel.Heading>
                    <Panel.Title> { panel.title } </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  </Panel.Body>
                </Panel>
              ) : (
                <Panel key={ panel.title }>
                  <Panel.Heading>
                    <Panel.Title>
                      { panel.title }
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {panel.title === 'COMMERCIAL SETTINGS' ? (
                      <div>
                        {panel.sections.map(section => <div key={ section.id }>
                          {!merchantSettingsV2 && (
                            <>
                              <h4>{ section.tables === "clientBuyMidSettings" ? "PAYMENT" : "PAYOUT" } METHODS</h4>
                              <hr/>
                            </>
                          )}
                          {state[section.tables].map(tableElem => {
                            return (
                              <div className="mids-table-wrapper" key={ tableElem.type }>
                                <p style={ { fontSize: "16px" } }> { tableElem.label } </p>
                                <table key={ tableElem.type } className="table table-striped mids-table">
                                  <thead>
                                    <tr>
                                      {!merchantSettingsV2 ? (
                                        <>
                                          <th> { section.tables === "clientBuyMidSettings" ? "Payment" : "Payout" } Methods </th>
                                          <th> Provider </th>
                                          <th> MID name </th>
                                          <th> Currency </th>
                                        </>
                                      ) : (
                                        <>
                                          <th> Currency </th>
                                          <th> Method </th>
                                          <th> Provider </th>
                                          <th> MID ID </th>
                                          <th> Traffic </th>
                                        </>
                                      )}
                                      {tableElem.fields.map(column => {
                                        return (
                                          <th key={ column.name }> { column.label } </th>
                                        );
                                      })}
                                      {foundPermission && foundPermission?.state && (
                                        <th style={ { textAlign: "center" } }>Merchant Dashboard</th>
                                      )}
                                      {/* TEMP: Comment out Tiers */}
                                      {/* <th></th> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {tableElem.merchantSettingsRows.map((row, j) => {   
                                      return [
                                        <tr
                                          key={ i }
                                          id={ tableElem.merchantSettingsRows[j].id }
                                        >
                                          {!merchantSettingsV2 ? (
                                            <>
                                              <td className="td-element td-disabled"> { row.paymentMethod } </td>
                                              <td className="td-element td-disabled"> { row.psp } </td>
                                              <td className="td-element td-disabled"> { `${row.midName} (${row.midSettingsId})` } </td>
                                              <td className="td-element td-disabled"> { row.currency } </td>
                                            </>
                                          ) : (
                                            <>
                                              <td className="td-element td-disabled"> { row.currency } </td>
                                              <td className="td-element td-disabled"> { row.paymentMethod } </td>
                                              <td className="td-element td-disabled"> { row.psp } </td>
                                              <td className="td-element td-disabled"> { row.midSettingsId } </td>
                                              <td className="td-element td-disabled"> { row.traffic } </td>
                                            </>
                                          )}
                                          {tableElem.fields.map(column => {
                                            return (
                                              <td
                                                key={ column.name }
                                                onDoubleClick={ () => this.onTableDataClick(row.id, section.clickMap, column.name, tableElem.type, true) }
                                                className={ `td-element ${this.isFieldDisabled(column.name) || !this.checkIfEditAllowed(row, column.name) ? "td-disabled number" : ""}` }
                                              >
                                                {this.isFieldDisabled(column.name) || (state[section.clickMap][tableElem.type] && state[section.clickMap][tableElem.type][row.id] && !state[section.clickMap][tableElem.type][row.id][column.name]) ? (
                                                  <span>
                                                    { this.mapItems(row[column.name], column.arrayName).label || (row.values[column.name] || row.values[column.name] === 0 ? row.values[column.name] : "") }
                                                  </span>
                                                ) : (
                                                  <input
                                                    type="number"
                                                    value={ this.getValue(row.values[column.name]) }
                                                    className={ `table-input ${state[section.errorsMap][tableElem.type] && state[section.errorsMap][tableElem.type][row.id] && state[section.errorsMap][tableElem.type][row.id][column.name] ? "td-error" : ""}` }
                                                    ref={ (input) => this.setRef(row.id, column.name, input, tableElem.type, section.refs) }
                                                    onKeyDown={ (event) => this.onKeyDown(event, row.id, section.clickMap, column.name, tableElem.type) }
                                                    onChange={ (event) => this.onChangeGeneralSettings(event, column.name, row.id, tableElem.type, section.tables) }
                                                    onBlur={ () => this.onTableDataClick(row.id, section.clickMap, column.name, tableElem.type) }
                                                    onDoubleClick={ (e) => e.stopPropagation() }
                                                  />
                                                )}
                                                {column.isPercents && row.values.hasOwnProperty(column.name) && (
                                                  <span className="percent-sign"> % </span>
                                                )}
                                              </td>
                                            );
                                          })}
                                          {foundPermission && foundPermission?.state && (
                                            <td>
                                              <Toggler
                                                className="center-align"
                                                active={ !tableElem.merchantSettingsRows[j].hideButton }
                                                value={ section.tables }
                                                id={ tableElem.merchantSettingsRows[j].id }
                                                onClick={ this.onTogglerClick }
                                              />
                                            </td>
                                          )}
                                          {/* TEMP: Comment out Tiers */}
                                          {/* <td>
                                            <Button
                                              id={ tableElem.merchantSettingsRows[j].id }
                                              type="submit"
                                              className="btn defaultBtn"
                                              onClick={ (e) =>  this.addTiersButton( tableElem.merchantSettingsRows[j], e.target.id,j, tableElem) }
                                            >
                                          Add Tiers
                                            </Button> 
                                          </td> */}
                                        </tr>, 
                                        <tr key= { "my" }>
                                          {tableElem.merchantSettingsRows[j].processingFeeRowDTOList.length > 0 ?
                                            <ClientsTableTiers
                                              items={ [{tableElem},{j},{inputErrorsMap} ] }
                                              checkButtonCLick={ checkButtonCLick }
                                              editable={ true }
                                              selectedItems={ processingFeeRowDTOList }
                                              onTemporaryChange={ this.onTypesTemporaryChange }
                                              onChange={ this.onTypesChange }
                                            />
                                            : tableElem.merchantSettingsRows[j].id === addTiers ?
                                              <ClientsTableTiers
                                                items={ [{tableElem},{j},{inputErrorsMap} ] }
                                                checkButtonCLick={ checkButtonCLick }
                                                editable={ true }
                                                selectedItems={ processingFeeRowDTOList }
                                                onTemporaryChange={ this.onTypesTemporaryChange }
                                                onChange={ this.onTypesChange }
                                              /> :[]} 
                                        </tr>];
                                    })}
                                  </tbody>                                   
                                </table>
                              </div>);
                          })}
                        </div>
                        )}
                        <Row flexGrow={ 1 } horizontal='end' wrap={ true } vertical='start'>
                          { viewOrEdit !== 1 && !loadingGeneralSettings && (
                            <Column flexGrow={ 0 } vertical='end' className="input-column">
                              <CustomButton
                                title="Save"
                                type="submit"
                                disabled={ isSubmitting }
                                onClick={ (e) => this.submitClientSettings(e) }
                              />
                            </Column>
                          )}
                        </Row>
                      </div>
                    ) : (panel.id === 'payinDefaultCommercials' || panel.id === 'payoutDefaultCommercials') ? (
                      <DefaultCommercialSettings
                        baseCurrencies={ baseCurrencies }
                        clientId={ this.props.clientId }
                        isSubmittingCommercials={ isSubmittingCommercials }
                        loadingGeneralSettings={ loadingGeneralSettings }
                        panel={ panel }
                        panels={ this.state.panels }
                        paymentMethods={ this.state.paymentMethods }
                        payoutMethods={ this.state.payoutMethods }
                        trafficTypes={ this.state.trafficTypes }
                        payinDefaultCommercials={ this.state.payinDefaultCommercials }
                        payoutDefaultCommercials={ this.state.payoutDefaultCommercials }
                        viewOrEdit={ viewOrEdit }
                      />
                    ) : (
                      <form autoComplete="off">
                        <input type="password" autoComplete="new-password" style={ { display: "none" } }/>
                        {(panel.form === "mainForm" ? this.filterPanelRows(panel) : panel.rows).map((row, index) => {
                          return <Row key={ index } flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' className='client-informations'>
                            {row.map((column, idx) => {
                              return column.id && column.id !== "apiKey" && (
                                <Column
                                  key={ `${panel.title}-${index}-${idx}` }
                                  vertical='start'
                                  alignSelf='start'
                                  className={ (column.type !== "select-input" ? "input-column " : "select-input-column") + (!column.id ? "extra-column" : "") + (column.isDependent ? !this.getValue(state[panel.form][column.dependencyField]) && "hidden" : "") }
                                >
                                  <div style={ { display: column.type === "2FASettings" ? "flex" : "initial" } }>
                                    {(!column.type && column.id !== "confirmPassword" && column.id !== "password" && !!this.getValue(state[panel.form][column.id])) && (
                                      <Tooltip id={ column.id } />
                                    )}
                                    <label> { column.label } </label>
                                        
                                    <div
                                      data-tooltip-id={ column.id }
                                      data-tooltip-place="top"
                                      data-tooltip-variant="light"
                                      data-tooltip-content={ !column.type && column.id !== "confirmPassword" && column.id !== "password" && this.getValue(state[panel.form][column.id]) ? this.getValue(state[panel.form][column.id]) : null }
                                    >
                                      {!column.type ? (
                                        column.id !== "confirmPassword" ? (
                                          <input
                                            className={ 'form-control ' + (state[panel.form].errors.has(column.id) ? 'error-field' : '') }
                                            type={ column.input ? (isVisiblePassword ? "text" : "password") : "text" }
                                            disabled={ this.isFieldDisabled(column.id) || column.disabled }
                                            value={ this.getValue(state[panel.form][column.id]) }
                                            onChange={ (event) => this.onValueChange(event, column.id, panel.form) }
                                          />
                                        ) : (
                                          <input
                                            type={ isVisiblePassword ? "text" : "password" }
                                            autoComplete="off"
                                            className={ "form-control " + (!this.arePasswordsIdentical() && confirmPasswordTouched ? 'error-field' : "") }
                                            value={ confirmPassword || '' }
                                            disabled={ this.isFieldDisabled() }
                                            onChange={ (value) => this.onConfirmPasswordChange(value) }
                                          />
                                        )) : column.type === "select" ? (
                                        <Select
                                          id={ column.id }
                                          name={ column.id }
                                          value={ state[panel.form][column.id] || "" }
                                          disabled={ this.isFieldDisabled(column.id) || column.disabled }
                                          clearable={ false }
                                          onChange={ (event) => this.onValueChange(event, column.id, panel.form) }
                                          options={ this.getSelectOptions(column) }
                                        />
                                      ) : column.type === "select-input" ? (
                                        <Row flexGrow={ 1 } vertical='start' className="select-input-row">
                                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                            <Select
                                              id={ column.id }
                                              name={ column.id }
                                              value={ state[panel.form][column.id] || "" }
                                              disabled={ this.isFieldDisabled(column.id) || column.disabled }
                                              clearable={ false }
                                              style={ { marginRight: '15px' } }
                                              onChange={ (event) => this.onValueChange(event, column.id, panel.form) }
                                              options={ column.options }
                                            />
                                          </Column>

                                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                            <input
                                              type="number"
                                              disabled={ this.isFieldDisabled(column.value.id) }
                                              className={ 'form-control ' + (state[panel.form].errors.has(column.value.id) ? 'error-field' : '') }
                                              value={ this.getValue(state[panel.form][column.value.id]) }
                                              onChange={ (event) => this.onValueChange(event, column.value.id, panel.form) }/>
                                            {
                                              state[panel.form][column.id] === "Percent" && <span className="percent-sign-complex"> % </span>
                                            }
                                          </Column>
                                        </Row>
                                      ) : column.type === "multiselect" ? (
                                        <Multiselect
                                          isError={ state[panel.form].errors.has(column.id) }
                                          selectedItems={ this.mapSelectedItems(panel.form, "baseCurrencies", "currency") }
                                          items={ baseCurrencies }
                                          disabled={ this.isFieldDisabled(column.id) }
                                          type={ "baseCurrencies" }
                                          onChange={ (value, fieldName) => this.onValueChange(value, fieldName, panel.form) }
                                        />
                                      ) : column.type === "checkbox" ? (
                                        <Column
                                          flexGrow={ 1 }
                                          vertical='start'
                                          alignSelf='start'
                                          className={ `input-column styled-input--square` }
                                        >
                                          <div className="styled-input-single">
                                            <input
                                              type="checkbox"
                                              id={ column.id }
                                              checked={ this.getValue(state[panel.form][column.id]) }
                                              onChange={ (value) => this.onValueChange(value, column.id, panel.form) }
                                              disabled={ this.isFieldDisabled(column.id) }
                                            />
                                            <label
                                              style={ { fontWeight: "normal", color: this.isFieldDisabled(column.id) ? "#CCCCCC" : ""} }
                                              htmlFor={ column.id }
                                            >
                                              { column.checkboxLabel }
                                            </label>
                                          </div>
                                        </Column>
                                      ) : column.type === "generatePassword" ? (
                                        <Column vertical='start' alignSelf='center' className="actions-column generate-password-column">
                                          <button
                                            type="button"
                                            className="toggle"
                                            onClick={ (e) => this.togglePasswordView(e) }
                                          >
                                            <img
                                              src={ isVisiblePassword ? VisibilityIcon : VisibilityOffIcon }
                                              alt={ isVisiblePassword ? "show" : "hide" }
                                              className="visibilityIcon"
                                            />
                                          </button>
                                          <button
                                            type="button"
                                            className="toggle"
                                            onClick={ () => this.generateMerchantPassword() }
                                          >
                                            <img src={ GenerateIcon } alt="generate" />
                                          </button>
                                        </Column>
                                      ) : column.type === "resetQRSettings" || column.type === "apiKeyDetails" ? (
                                        <div className="twoFA-column merchant-settings">
                                          <button
                                            type="button"
                                            className="reset"
                                            onClick={ () => this.onButtonClick(column.type) }
                                          >
                                            { column.btnLabel }
                                          </button>
                                        </div>
                                      ) : (
                                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                          <span/>
                                        </Column>
                                      )}
                                    </div>
                                    {(state[panel.form].errors.has(column.id) ||
                                        (column.value && this.state[panel.form].errors.has(column.value.id))) && (
                                      <p className="error-message">
                                        { state[panel.form].errors.get(column.id) ||
                                              state[panel.form].errors.get(column.value.id)
                                        }
                                      </p>
                                    )}
                                    { column.id === "confirmPassword" && !this.arePasswordsIdentical() && confirmPasswordTouched && (
                                      <p className="error-message">
                                        { !confirmPassword ? "Confirmation is required."  : "Passwords are not identical." }
                                      </p>
                                    )}
                                  </div>
                                </Column>);
                            })}
                          </Row>;
                        })}
                        <Row flexGrow={ 1 } horizontal='end' wrap={ true } vertical='start' style={ { margin: !viewOrEdit ? "50px 0 200px" : "0" } }>
                          { !viewOrEdit && !loadingGeneralSettings && (
                            <Column flexGrow={ 0 } vertical='end' className="input-column">
                              <CustomButton
                                title="Save"
                                type="submit"
                                disabled={ isSubmitting }
                                onClick={ (e) => this.submitClientSettings(e) }
                              />
                            </Column>
                          )}
                        </Row>
                      </form>
                    )}
                  </Panel.Body>
                </Panel>
              );
            })
            }
          </Column>
        </Row>
      </Column>

      {showAlert && (
        <Alert
          show={ showAlert }
          title={ alertType }
          type={ alertType }
          text={ alertType === "success" && !alertMessage ? `Merchant successfully ${viewOrEdit === 2 ? 'updated' : 'saved'}.` : alertMessage }
          confirmButtonColor="#187EED"
          onConfirm={ this.onConfirm }
        />
      )}

      {showModal && (
        <Modal
          showModal={ showModal }
          title={ modalTitle }
          buttonLabel="OK"
          modalType="content"
          data={ (
            <>
              <Row flexGrow={ 1 } horizontal='end' wrap={ true } vertical='start'>
                <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column modal-input-column">
                  <label> Merchant API Key </label>
                  <input
                    className={ 'form-control ' + (state.mainForm.errors.has("postbackApiKey") ? 'error-field' : '') }
                    type={ isMerchantApiKeyReset || isPostbackApiKeyVisible ? "text" : "password" }
                    disabled={ this.isFieldDisabled("postbackApiKey") || !isMerchantApiKeyReset }
                    readOnly={ !isMerchantApiKeyReset }
                    value={ this.getValue(state.mainForm.postbackApiKey) }
                    onChange={ (event) => this.onValueChange(event, "postbackApiKey", "mainForm") }
                  />
                  <div className="buttons">
                    <button
                      type="button"
                      className="reset"
                      onClick={ () => this.onResetApiKey("isMerchantApiKeyReset", "postbackApiKey") }
                    >
                      reset
                    </button>
                    {roleId === "MASTER_TECH" && !isMerchantApiKeyReset && (
                      <button
                        type="button"
                        className="toggle"
                        onClick={ () => this.setState({ isPostbackApiKeyVisible: !this.state.isPostbackApiKeyVisible }) }
                      >
                        <img
                          src={ isPostbackApiKeyVisible ? VisibilityIcon : VisibilityOffIcon }
                          alt={ isPostbackApiKeyVisible ? "show" : "hide" }
                          className="visibilityIcon"
                        />
                      </button>
                    )}
                  </div>
                </Column>
              </Row>
              <Row flexGrow={ 1 } horizontal='end' wrap={ true } vertical='start'>
                <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column styled-input--square">
                  <div className="styled-input-single">
                    <input
                      type="checkbox"
                      id="checkPayoutHash"
                      checked={ this.getValue(state.mainForm.checkPayoutHash) }
                      onChange={ (value) => this.onValueChange(value, "checkPayoutHash", "mainForm") }
                      disabled={ this.isFieldDisabled("checkPayoutHash") }
                    />
                    <label
                      style={ { fontWeight: "normal", color: this.isFieldDisabled("checkPayoutHash") ? "#CCCCCC" : ""} }
                      htmlFor="checkPayoutHash"
                    >
                      Enable Secret Key
                    </label>
                  </div>
                  <div className={ `modal-input-column ${this.getValue(state.mainForm.checkPayoutHash) ? "" : "hidden"}` }>
                    <label> Secret Key </label>
                    <input
                      className={ 'form-control ' + (state.mainForm.errors.has("payoutApiKey") ? 'error-field' : '') }
                      type={ isSecretKeyReset ? "text" : "password" }
                      disabled={ this.isFieldDisabled("payoutApiKey") || !isSecretKeyReset }
                      readOnly={ !isSecretKeyReset }
                      value={ this.getValue(state.mainForm.payoutApiKey) }
                      onChange={ (event) => this.onValueChange(event, "payoutApiKey", "mainForm") }
                    />
                    <div className="buttons">
                      <button
                        type="button"
                        className="reset"
                        onClick={ () => this.onResetApiKey("isSecretKeyReset", "payoutApiKey") }
                      >
                        reset
                      </button>
                    </div>
                  </div>
                </Column>
              </Row>
            </>
          ) }
          isLoading={ false }
          handleClose={ this.handleCloseModal }
        />
      )}
        
    </Row>;
  }
}

export default ManageClient;