import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Row } from 'simple-flexbox';

import AddBlacklist from '@modules/blacklist/AddBlacklist';
import AdminPanel from '@modules/adminPanel/AdminPanel';
import AgentManagement from '@modules/userManagement/AgentManagement';
import API from '@modules/softwareIntegration/API';
import APIData from '@modules/apiData/APIData';

import BankCodes from '@modules/bankCodes/BankCodes';
import Blacklist from '@modules/blacklist/Blacklist';

import Clients from '@modules/clients/Clients';
import Companies from '@modules/companies/Companies';
import CurrencyTable from '@modules/currencyTable/CurrencyTable';

import EditClient from '@modules/clients/EditClient';
import EditCompany from '@modules/companies/EditCompany';
import EditMid from '@modules/mids/EditMid';
import EditPSP from '@modules/psps/EditPSP';
import EditUser from '@modules/userManagement/EditUser';
import EmptyScreen from '@sm/components/EmptyScreen';

import GenerateOrder from '@modules/generateOrder/GenerateOrder';
import GenerateReport from '@modules/generateReport/GenerateReport';

import ImportPayouts from '@modules/payouts/ImportPayouts';

import Logs from '@modules/logs/Logs';

import ManageBankCodes from '@modules/bankCodes/ManageBankCodes';
import ManageClient from '@modules/clients/ManageClient';
import ManageCompany from '@modules/companies/ManageCompany';
import ManageMid from '@modules/mids/ManageMid';
import ManagePSP from '@modules/psps/ManagePSP';
import ManageUser from '@modules/userManagement/ManageUser';
import ManualInput from '@modules/data/ManualInput';
import Mids from '@modules/mids/Mids';
import MyAccount from '@modules/myAccount/MyAccount';

import Notes from '@modules/softwareIntegration/Notes';

import Onboarding from '@modules/mids/Onboarding';

import PayoutCascading from '@modules/pspCascading/PayoutCascading';
import PendingAgents from '@modules/referrals/PendingAgents';
import PendingReferrals from '@modules/referrals/PendingReferrals';
import PspCascading from '@modules/pspCascading/PspCascading';
import PSPs from '@modules/psps/PSPs';

import ReferralLinks from '@modules/referrals/ReferralLinks';

import UploadBlacklist from '@modules/blacklist/UploadBlacklist';
import UserManagement from '@modules/userManagement/UserManagement';

import ViewClient from '@modules/clients/ViewClient';
import ViewCompany from '@modules/companies/ViewCompany';
import ViewMid from '@modules/mids/ViewMid';
import ViewPSP from '@modules/psps/ViewPSP';
import ViewUser from '@modules/userManagement/ViewUser';

import { crudActions } from '@sm/services/crudActions';
import { FETCH_PERMISSIONS } from '@sm/actions/types';
import BankCodesExport from "./modules/bankCodes/BankCodesExport";
const combineReducers =  require('@sm/reducers/index');

class Module extends Component {
  state = {
    permissions: [],
    roleId: ""
  };

  componentDidMount() {
    crudActions.get(`v1/myaccount/me`).then(
      (user) => {
        if (user) {
          combineReducers.default.dispatch({ type: FETCH_PERMISSIONS, payload: user.permissions, roleId: user.roleId, wlOtcEnabled: user.wlOtcEnabled });
          this.setState({
            permissions: user.permissions,
            roleId: user.roleId
          });
        }
      }
    ).catch(
      err => {
        localStorage.removeItem("loginAsUser");
        localStorage.removeItem("loginAsUserEmail");
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message,
          });
        }
      }
    );
  };

  checkPageAccess = (permissionName) => {
    const { permissions } = this.state;
    const foundPermission = permissions.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  checkPageAccessCategory = (categoryName) => {
    const { permissions } = this.state;
    const foundPermissions = permissions.filter(elem => elem.category === categoryName);
    return foundPermissions.some(elem => elem.state);
  };

  checkRole = (roleName) => {
    const { roleId } = this.state;
    return roleId === roleName;
  };

  render() {
    return (
      <Row flexGrow={ 1 }>
        <Route exact path="/" component={ EmptyScreen }/>
        <Route exact path="/my-account" component={ MyAccount }/>
        <Route exact path="/docs" component={ API }/>
        { this.checkPageAccess("NOTES_CREATE") && <Route exact path="/notes" component={ Notes }/> }

        { this.checkPageAccessCategory("INBOUND_SETTLEMENT") && <Route exact path="/inbound-settlement" component={ ManualInput }/> }
        { this.checkPageAccessCategory("FINANCE SECTION INCL. SETTLEMENTS") && <Route exact path="/manual-input" component={ ManualInput }/> }
        { this.checkPageAccessCategory("GENERATE_ORDER") && <Route exact path="/generate-order" component={ GenerateOrder }/> }

        { this.checkPageAccess("PAYOUT_EXCEL_UPLOAD") && <Route exact path="/import-payouts" component={ ImportPayouts }/> }

        { this.checkPageAccessCategory("REFERRALS") && <Route exact path="/pending-referrals" component={ PendingReferrals }/> }
        { this.checkPageAccessCategory("REFERRALS") && <Route exact path="/pending-agents" component={ PendingAgents }/> }
        { this.checkPageAccess("REFERRAL_LINKS") && <Route exact path="/referral-links" component={ ReferralLinks }/> }

        { this.checkPageAccessCategory("DASHBOARD") && <Route exact path="/dashboard" component={ APIData }/> }

        { this.checkPageAccessCategory("REPORTS") && <Route exact path="/generate-reports" component={ GenerateReport }/> }
        { this.checkPageAccessCategory("REPORTS") && <Route exact path="/transactions" component={ GenerateReport }/> }

        { this.checkPageAccessCategory("ADMIN_PANEL") && <Route exact path="/admin-panel" component={ AdminPanel }/> }

        { this.checkPageAccessCategory("CURRENCY_TABLE") && <Route exact path="/currency-table" component={ CurrencyTable }/> }

        { this.checkPageAccessCategory("CASCADING") && <Route exact path="/provider-priority" component={ PspCascading }/> }
        { this.checkPageAccessCategory("CASCADING") && <Route exact path="/provider-payout-priority" component={ PayoutCascading }/> }

        { this.checkPageAccessCategory("SYSTEM_LOGS") && <Route exact path="/logs" component={ Logs }/> }

        { this.checkPageAccessCategory("USER_MANAGEMENT") && <Route exact path="/user-management" component={ UserManagement }/> }
        { this.checkPageAccessCategory("USER_MANAGEMENT") && <Route exact path="/agent-management" component={ AgentManagement }/> }
        { this.checkPageAccess("USER_EDIT") && <Route exact path="/add-user" component={ ManageUser }/> }
        { this.checkPageAccess("USER_VIEW") && <Route exact path="/view-user/:userId" component={ ViewUser }/> }
        { this.checkPageAccess("USER_EDIT") && <Route exact path="/edit-user/:userId" component={ EditUser }/> }

        { this.checkPageAccessCategory("MERCHANTS") && <Route exact path="/merchants" component={ Clients }/> }
        { this.checkPageAccess("MERCHANTS_EDIT") && <Route exact path="/add-merchant" component={ ManageClient }/> }
        { this.checkPageAccess("MERCHANTS_VIEW") && <Route path="/view-merchant/:clientId" component={ ViewClient }/> }
        { this.checkPageAccess("MERCHANTS_EDIT") && <Route path="/edit-merchant/:clientId" component={ EditClient }/> }

        { this.checkPageAccessCategory("MIDS") && <Route exact path="/mids" component={ Mids }/> }
        { this.checkPageAccess("MIDS_VIEW") && <Route path="/view-mid/:midId" component={ ViewMid }/> }
        { this.checkPageAccess("MIDS_VIEW") && <Route path="/onboarding-process" component={ Onboarding }/> }
        { this.checkPageAccess("MIDS_EDIT") && <Route path="/edit-mid/:midId" component={ EditMid }/> }
        { this.checkPageAccess("MIDS_EDIT") && <Route exact path="/add-mid" component={ ManageMid }/> }

        { this.checkRole("MASTER_TECH") && <Route exact path="/white-labels" component={ Companies }/> }
        { this.checkRole("MASTER_TECH") && <Route exact path="/add-white-label" component={ ManageCompany }/> }
        { this.checkRole("MASTER_TECH") && <Route path="/view-white-label/:companyId" component={ ViewCompany }/> }
        { this.checkRole("MASTER_TECH") && <Route path="/edit-white-label/:companyId" component={ EditCompany }/> }

        { (this.checkRole("MASTER_TECH") || this.checkPageAccessCategory("BLACKLIST")) && <Route exact path="/blacklist" component={ Blacklist }/> }
        { (this.checkRole("MASTER_TECH") || this.checkPageAccessCategory("BLACKLIST")) && <Route exact path="/add-blacklist" component={ AddBlacklist }/> }
        { (this.checkRole("MASTER_TECH") || this.checkPageAccessCategory("BLACKLIST")) && <Route exact path="/upload-blacklist" component={ UploadBlacklist }/> }

        { (this.checkRole("MASTER_TECH") || this.checkPageAccessCategory("BANKLIST")) && <Route exact path="/bank-codes" component={ BankCodes }/> }
        { (this.checkRole("MASTER_TECH") || this.checkPageAccessCategory("BANKLIST")) && <Route exact path="/bank-codes-export" component={ BankCodesExport }/> }
        { (this.checkRole("MASTER_TECH") || this.checkPageAccessCategory("BANKLIST")) && <Route exact path="/add-bank-code" component={ ManageBankCodes }/> }

        { (this.checkRole("MASTER_TECH") || this.checkPageAccessCategory("PROVIDERS")) && <Route exact path="/providers" component={ PSPs }/> }
        { (this.checkRole("MASTER_TECH") || this.checkPageAccess("PSP_EDIT")) && <Route exact path="/add-provider" component={ ManagePSP }/> }
        { (this.checkRole("MASTER_TECH") || this.checkPageAccess("PSP_VIEW")) && <Route path="/view-provider/:pspId" component={ ViewPSP }/> }
        { (this.checkRole("MASTER_TECH") || this.checkPageAccess("PSP_EDIT")) && <Route path="/edit-provider/:pspId" component={ EditPSP }/> }
      </Row>
    );
  }
}

export default Module;