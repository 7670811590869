import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Datetime from 'react-datetime';
import { saveAs } from 'file-saver';

import Alert from "@sm/components/custom/Alert";
import CustomButton from "@sm/components/custom/Button";
import Form from "@sm/core/Form";
import Multiselect from "@sm/components/custom/customMultiselect";
import PromptModal from '@modules/generateReport/PromptModal';
import Spinner from '@sm/components/Spinner';
import Table from "@sm/components/custom/Table";

import '@assets/css/blacklist.css';
import UserManagementIcon from '@assets/images/user-management.png';

import { crudActions } from "@sm/services/crudActions";
import { FETCH_PERMISSIONS } from '@sm/actions/types';
const store =  require('@sm/reducers/index');

const INTEGER_MAX = 2147483647;

class Blacklist extends Component {
  state = {
    controls: new Form({
      whiteLabelIds: [],
      email: "",
      mobileNumber: "",
      firstName: "",
      lastName: "",
      ipAddress: ""
    }, [{
      name: "whiteLabelIds",
      type: "isArray",
      rules: {
        required: true
      }
    }, {
      name: "email",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "mobileNumber",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "firstName",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "lastName",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "ipAddress",
      type: "isString",
      rules: {
        required: false
      }
    }]),

    companies: [],
    blacklistAccounts: [],
    filterOn: false,
    access: [],
    roleId: "",
    isLoading: true,
    isBlacklistLoading: false,
    isExportSettingsOpen: false,
    showAlert: false,
    alertType: "success",
    alertMessage: "",
    showPromptModal: false,
    accountToDelete: {},
    currentPage: 1,
    limit: 100,
    records: null
  };

  subscribeFunction = null;
  exportNode = null;

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside, false);
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      const roleId = storeState.roleId;
      this.setState({
        access: storeState.access,
        roleId
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    crudActions.get(`v1/companies/clients`)
      .then(
        (clients) => {
          if (clients) {
            const companiesList = clients.map(elem => elem.company);
            companiesList.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));
            this.setState({
              companies: companiesList,
              companiesClients: clients.reduce((accumulator, currentElem) => {
                accumulator[currentElem.company.value] = currentElem.clients.map(elem => elem.client);
                return accumulator;
              }, {}),
              isLoading: false
            }, () => {
              const { companies } = this.state;
              if (companies.length) {
                this.onValueChange(companies, 'whiteLabelIds');
              }
            });
          }
        }
      );
  };

  handleClickOutside = (event) => {
    if (this.exportNode && this.exportNode.contains(event.target)) {
      this.setState({
        isSortSettingsOpen: false
      });

      return;
    }

    this.setState({
      isExportSettingsOpen: false
    });
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  onValueChange = (event, field) => {
    const { controls } = this.state;
    if (!event) {
      controls[field] = event;
    } else if (event.target) {
      controls[field] = event.target.value;
    } else if (event.value) {
      controls[field] = event.value;
    } else {
      controls[field] = event.map(elem => elem.value);
    }

    this.setState({
      controls
    });
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  tableColumns = () => {
    const columns = [{
      value: "email",
      label: "Email"
    }, {
      value: "firstName",
      label: "First Name"
    }, {
      value: "lastName",
      label: "Last Name"
    }, {
      value: "mobileNumber",
      label: "Phone Number"
    }, {
      value: "ipAddress",
      label: "IP Address"
    }, {
      value: "merchantId",
      label: "Merchant ID"
    }];

    if (this.checkPageAccess("BLACKLIST_EDIT")) {
      columns.push({
        value: "id",
        label: "Actions",
        centerAligned: true,
        className: "btn remove-bl-account",
        buttonLabel: "Remove",
        isButton: true,
        triggerEvent: (acc) => this.removeBlacklistAccount(acc),
        isButtonDisabled: (acc) => this.checkRemoveAvailability(acc),
        isButtonLoading: (data) => { return false; }
      });
    }

    return columns;
  };

  checkRemoveAvailability = (account) => {
    const { companiesClients, controls, roleId } = this.state;
    if (!account) return true;

    if (roleId === "WL_ADMIN" || roleId === "WL_USER") {
      const availableClients = companiesClients[controls.whiteLabelIds[0]];
      return !availableClients.find(item => item.value === account.merchantId.toString());
    }

    return false;
  };

  onExportOpen = () => {
    this.setState({
      isExportSettingsOpen: !this.state.isExportSettingsOpen
    });
  };

  removeBlacklistAccount = (account) => {
    if (!account) return;

    this.setState({
      showPromptModal: true,
      accountToDelete: account
    });
  };

  handleConfirmPromptModal = () => {
    const { accountToDelete, blacklistAccounts } = this.state;
    crudActions.remove(`v1/blacklist/${accountToDelete.id}`)
      .then(() => {
        const filtered = blacklistAccounts.filter(acc => acc.id !== accountToDelete.id);
        this.setState({
          blacklistAccounts: filtered,
          accountToDelete: {},
          showPromptModal: false
        });
      })
      .catch(() => {
        this.setState({
          showPromptModal: false
        });
      });
  };

  handleClosePromptModal = () => {
    this.setState({
      showPromptModal: false,
      accountToDelete: {}
    });
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  onGenerateBlacklist = (type) => {
    const { controls, currentPage, limit } = this.state;
    const isControlsFormValid = controls.isFormValid();

    this.setState({
      controls
    });

    if (isControlsFormValid) {
      this.setState({
        isBlacklistLoading: true
      });

      const data = {
        ...controls.data(),
        pageNumber: type === "file" ? 1 : currentPage,
        limit: type === "file" ? INTEGER_MAX : limit
      };

      let url = `v1/blacklist/search`;
      if (type === "file") {
        url += `/xlsx`;
      }

      crudActions.post(url, data)
        .then(response => {
          if (type === "list") {
            if (response) {
              this.setState({
                isBlacklistLoading: false,
                filterOn: true,
                blacklistAccounts: response.data,
                records: response.count
              });
            }
          } else {
            if (response && response.ok) {
              response.blob().then(
                result => {
                  saveAs(result, `Blacklist-${Datetime.moment().format("DD-MM-YYYY")}.xlsx`);
                }
              );
            } else {
              return Promise.reject({
                message: "Request can not be processed."
              });
            }
          }
        })
        .catch(err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isBlacklistLoading: false
            });
          }
        });
    }
  };

  mapSelectedItems = (type, lookups) => {
    const field = this.state.controls[type];
    const lookup = this.state[lookups];
    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  handleChangePage = (event) => {
    const { currentPage } = this.state;
    let pageNum = currentPage;

    if (event.target.getAttribute('data-testid') === "NavigateBeforeIcon"
      || event.target.getAttribute('aria-label') === "Go to previous page") {
      pageNum--;
    } else if (event.target.getAttribute('data-testid') === "NavigateNextIcon"
      || event.target.getAttribute('aria-label') === "Go to next page") {
      pageNum++;
    } else if (event.target.textContent) {
      pageNum = event.target.textContent;
    }
   
    this.setState({ currentPage: pageNum }, () => {
      this.onGenerateBlacklist('list');
    }); 
  };

  render() {
    const {
      blacklistAccounts,
      records,
      controls,
      companies,
      currentPage,
      filterOn,
      isExportSettingsOpen,
      isLoading,
      limit,
      roleId,
      showAlert,
      alertType,
      alertMessage,
      showPromptModal
    } = this.state;

    const columns = this.tableColumns();
    const totalPageCount = Math.ceil(records / limit);

    if (!isExportSettingsOpen) {
      this.exportNode = null;
    }

    return (
      <Row flexGrow={ 1 } className="module generateReports blacklist" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
                <img src={ UserManagementIcon } alt="" style={ { marginRight: 10 } }/>
                Blacklist
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                {this.checkPageAccess("BLACKLIST_EDIT") && (
                  <Column horizontal='end' style={ {paddingRight: 15} }>
                    <Row horizontal='end' vertical='center' style={ {color: '#ccc', fontSize: '12px'} }>
                      <NavLink to={ `/add-blacklist` } className="btn add-user-button">
                        ADD USER
                      </NavLink>
                      <NavLink to={ `/upload-blacklist` } className="btn upload-button">
                        UPLOAD
                      </NavLink>
                    </Row>
                  </Column>
                )}
              </Row>
            </Column>
          </Row>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    SEARCH BLACKLIST
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { isLoading ? (
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  ) : (
                    <div className="panel-content" style={ {overflow: 'unset'} }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> White Label </label>
                          <Multiselect
                            id="whiteLabelIds"
                            name="whiteLabelIds"
                            selectedItems={ this.mapSelectedItems("whiteLabelIds", "companies") }
                            items={ companies }
                            required={ true }
                            disabled={ roleId !== "MASTER_TECH" && roleId !== "MASTER_ADMIN" }
                            type={ "whiteLabelIds" }
                            onChange={ (value) => this.onValueChange(value, 'whiteLabelIds') }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Email </label>
                          <input
                            type="text"
                            className={ "form-control " + (controls.errors.has('email') ? 'error-field' : "") }
                            value={ controls.email || '' }
                            onChange={ (e) => this.onValueChange(e, "email") }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Phone Number </label>
                          <input
                            type="text"
                            className={ "form-control " + (controls.errors.has('mobileNumber') ? 'error-field' : "") }
                            value={ controls.mobileNumber || '' }
                            onChange={ (e) => this.onValueChange(e, "mobileNumber") }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> First Name </label>
                          <input
                            type="text"
                            className={ "form-control " + (controls.errors.has('firstName') ? 'error-field' : "") }
                            value={ controls.firstName || '' }
                            onChange={ (e) => this.onValueChange(e, "firstName") }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Last Name </label>
                          <input
                            type="text"
                            className={ "form-control " + (controls.errors.has('lastName') ? 'error-field' : "") }
                            value={ controls.lastName || '' }
                            onChange={ (e) => this.onValueChange(e, "lastName") }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> IP Address </label>
                          <input
                            type="text"
                            className={ "form-control " + (controls.errors.has('ipAddress') ? 'error-field' : "") }
                            value={ controls.ipAddress || '' }
                            onChange={ (e) => this.onValueChange(e, "ipAddress") }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label/>
                          <CustomButton
                            title="Search"
                            type="button"
                            onClick={ (e) => this.onGenerateBlacklist('list') }
                          />
                        </Column>                 
                      </Row>
                    </div>
                  )}
                </Panel.Body>
              </Panel>

              { filterOn && (
                <>
                  <Panel>
                    <Panel.Heading>
                      <Panel.Title>
                        REPORT GENERATED
                      </Panel.Title>
                      <div className="settings-panel">
                        <span className="setting" style={ { float: "right", paddingRight: "10px" } } onClick={ () => this.onExportOpen() }>
                          <svg className="setting-icon" viewBox="0 0 32 32">
                            <g>
                              <path clipRule="evenodd" d="M8.624,21.336h2.015c1.402-7.953,8.329-14,16.684-14   c0.35,0,0.683,0.003,1.019,0.006l-3.664,3.663c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293   c0.256,0,0.512-0.098,0.707-0.293L32,6.356l-5.907-6.063c-0.391-0.391-1.023-0.391-1.414,0c-0.391,0.391-0.391,1.023,0,1.414   l3.631,3.631c-0.318-0.001-0.62-0.003-0.945-0.003C17.895,5.336,10.066,12.271,8.624,21.336z" fillRule="evenodd" />
                              <path clipRule="evenodd" d="M29,15c-0.552,0-1,0.448-1,1v14H2V10h9c0.552,0,1-0.448,1-1   c0-0.552-0.448-1-1-1h-0.03H2c-1.104,0-2,0.896-2,2v20c0,1.104,0.896,2,2,2h26c1.104,0,2-0.896,2-2V16C30,15.448,29.552,15,29,15z" fillRule="evenodd" />
                            </g>
                          </svg>
                          <span className="text"> Export </span>
                        </span>
                      </div>
                      {isExportSettingsOpen && <div ref={ node => this.exportNode = node } className="export-settings">
                        <p className="export-setting-item" onClick={ () => this.onGenerateBlacklist('file') }> Export to Excel </p>
                      </div>
                      }
                    </Panel.Heading>
                    <Panel.Body>
                      <div className="blacklist-table">
                        <Table
                          columns={ columns }
                          data={ blacklistAccounts }
                          isStriped={ true }
                          defaultSortBy={ columns[0].value }
                          useArrow={ true }
                        />
                      </div>
                    </Panel.Body>
                  </Panel>

                  {totalPageCount > 0 && (
                    <Panel style={ {marginTop : "-13px", borderColor : "white", maxHeight: "105px", minHeight: "100px"} }>
                      <Panel.Body>
                        <div className="pagination">
                          <Pagination 
                            count={ totalPageCount } 
                            variant="outlined" 
                            shape="rounded" 
                            page={ Number(currentPage) }
                            onClick= { this.handleChangePage } 
                          />
                        </div>
                      </Panel.Body>
                    </Panel>
                  )}
                </>
              )}
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#DD6B55"
            onConfirm={ this.onConfirm }
          />
        )}

        <PromptModal
          title="Remove blacklist account"
          message="Do you want to delete an account from the Blacklist?"
          handleConfirm={ this.handleConfirmPromptModal }
          handleClose={ this.handleClosePromptModal }
          showModal={ showPromptModal }
        />
      </Row>
    );
  }
}

export default Blacklist;
