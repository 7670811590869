import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel } from "react-bootstrap";
import { Redirect } from 'react-router-dom';

import Alert from '@sm/components/custom/Alert';
import CustomButton from "@sm/components/custom/Button";
import PageHeader from "@sm/components/PageHeader";
import Form from "@sm/core/Form";
import Select from "@sm/components/custom/Select";

import '@assets/css/editClient.css';
import WhiteLabelIcon from '@assets/images/whitelabels.png';

import { crudActions } from "@sm/services/crudActions";
import { FETCH_PERMISSIONS } from '@sm/actions/types';
const combineReducers =  require('@sm/reducers/index');

class ManageCompany extends Component {
  state = {
    companyForm: new Form({
      name: "",
      shortName: "",
      apiLogin: "",
      apiPassword: "",
      apiUrl: "",
      agentId: null,
      agentProgramCurrency: "",
      cascadingMode: "1.0",
      approvalRequestId: null,
      monolithUrl: "",
      sandboxUrl: "",
      defaultTimezone: "",
      providerOnOffToggleVisible: false,
      otcEnabled: false,
      merchantSettingsV2: false,
      timeZoneType: "TZ_V1",
      masterBoUrl: "",
      clientBoUrl: "",
      agentBoUrl: ""
    }, [{
      name: "name",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "shortName",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "apiLogin",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "monolithUrl",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "sandboxUrl",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "apiPassword",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "apiUrl",
      type: "isUrl",
      rules: {
        required: false
      }
    }, {
      name: "agentId",
      type: "isNumber",
      rules: {
        required: false
      }
    }, {
      name: "agentProgramCurrency",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "cascadingMode",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "approvalRequestId",
      type: "isNumber",
      rules: {
        required: false
      }
    }, {
      name: "defaultTimezone",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "providerOnOffToggleVisible",
      type: "isBoolean",
      rules: {
        required: true
      }
    }, {
      name: "otcEnabled",
      type: "isBoolean",
      rules: {
        required: true
      }
    }, {
      name: "merchantSettingsV2",
      type: "isBoolean",
      rules: {
        required: true
      }
    }, {
      name: "timeZoneType",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "masterBoUrl",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "clientBoUrl",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "agentBoUrl",
      type: "isString",
      rules: {
        required: false
      }
    }]),

    agents: [],
    allTimezones: [],
    wlTimeZones: [],
    timeZoneTypes: [{
      label: "1.0 Multiple TZ",
      value: "TZ_V1"
    }, {
      label: "2.0 Mid TZ",
      value: "TZ_V2"
    }],

    otcWasUpdated: false,

    showAlert: false,
    alertType: "success",
    alertMessage: "",
    redirectToCompanies: false,
    isSubmitting: false
  };

  componentDidMount() {
    const companyId = this.props.companyId;
    const routeState = this.props.location && this.props.location.state;
    let approvalRequestId;
    if (routeState) {
      approvalRequestId = routeState.params.approvalRequestId;
    }

    if (approvalRequestId) {
      crudActions.get(`v1/approval/requests/${approvalRequestId}`).then(
        (requestData) => {
          if (requestData) {
            this.setState({
              companyForm: Object.assign(this.state.companyForm, {
                agentId: requestData.referredAgentId
              })
            });
          }
        }
      );
    }

    if (companyId) {
      Promise.all([
        crudActions.get(`v1/companies/${companyId}`),
        crudActions.get(`v1/adminpanel/company/${companyId}`)
      ]).then((data) => {
        const company = data[0];
        const dropdowns = data[1];

        this.setState({
          ...(company && { companyForm: Object.assign(this.state.companyForm, company) }),
          ...(dropdowns && {
            wlTimeZones: dropdowns.storedLookups.timeZones,
            currencies: dropdowns.storedLookups.baseCurrencies.map(item => ({ label: item, value: item }))
          })
        });
      });
    }

    Promise.all([
      crudActions.get("v1/mids/timezones"),
      crudActions.get('v1/agent/all')
    ]).then((data) => {
      const timeZones = data[0];
      const agents = data[1];

      this.setState({
        ...(timeZones && { allTimezones: timeZones }),
        ...(agents && { agents })
      });
    });

  };

  isFieldDisabled = (fieldName) => {
    const viewOrEdit = this.props.viewOrEdit;

    if (!viewOrEdit) {
      return false;
    }

    return viewOrEdit === 1;
  };

  getTimeZones = () => {
    const { allTimezones, wlTimeZones } = this.state;
    const { viewOrEdit } = this.props;
    if (!allTimezones) return [];

    if (!viewOrEdit) return allTimezones;

    return allTimezones.filter(timeZone => wlTimeZones.includes(timeZone.value));
  };

  onValueChange = (event, fieldName) => {
    let { companyForm } = this.state;
    const isNumber = companyForm.fieldRules.find(rule => rule.name === fieldName).type === "isNumber";
    const isBoolean = companyForm.fieldRules.find(rule => rule.name === fieldName).type === "isBoolean";
    
    if (isBoolean) {
      companyForm = Object.assign(companyForm, {
        [fieldName]: !companyForm[fieldName]
      });
    } else if (event.value) {
      companyForm = Object.assign(companyForm, {
        [fieldName]: isNumber && !isNaN(parseFloat(event.value)) ? parseFloat(event.value) : event.value
      });
    } else if (event.target) {
      companyForm = Object.assign(companyForm, {
        [fieldName]: isNumber && !isNaN(parseFloat(event.target.value)) ? parseFloat(event.target.value) : event.target.value
      });
    } else {
      companyForm = Object.assign(companyForm, {
        [fieldName]: event.map(elem => elem.value)
      });
    }

    companyForm.isFormValid();

    if (fieldName === "otcEnabled") {
      this.setState({
        otcWasUpdated: true
      });
    }
    this.setState({
      companyForm
    });
  };

  submitCompanySettings = () => {
    const { companyForm } = this.state;
    const companyId = this.props.companyId;
    const routeState = this.props.location && this.props.location.state;
    let approvalRequestId;
    if (routeState) {
      approvalRequestId = routeState.params.approvalRequestId;
    }

    const isFormValid = companyForm.isFormValid();
    this.setState({
      companyForm
    });

    if (isFormValid) {
      this.setState({
        isSubmitting: true
      });
      const data = companyForm.data();
      (companyId ? crudActions.put(`v1/companies`, Object.assign(data, {
        id: companyId
      })) : crudActions.post(`v1/companies`, Object.assign(data, {
        approvalRequestId: approvalRequestId
      }))).then(
        () => {
          this.setState({
            showAlert: true,
            alertType: "success",
            isSubmitting: false
          });
          if (this.state.otcWasUpdated) {
            crudActions.get(`v1/myaccount/me`).then(
              (user) => {
                if (user) {
                  combineReducers.default.dispatch({
                    type: FETCH_PERMISSIONS,
                    payload: user.permissions,
                    roleId: user.roleId,
                    wlOtcEnabled: user.wlOtcEnabled
                  });
                }
              }
            ).catch(
              (err) => {
                if (err && err.message) {
                  this.setState({
                    showAlert: true,
                    alertType: "error",
                    alertMessage: err.message,
                    isSubmitting: false
                  });
                }
              }
            );
          }
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isSubmitting: false
            });
          }
        }
      );
    } else {
      this.setState({
        showAlert: true,
        alertType: "error",
        alertMessage: "Some fields have errors. Please, fix them."
      });
    }
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: "",
      redirectToCompanies: this.state.showAlert && this.state.alertType === "success"
    });
  };

  cascadingModesOptions = () => {
    const modes = ["1.0", "2.0"];
    return modes.map(item => ({ label: item, value: item }));
  };

  render() {
    const { viewOrEdit } = this.props;
    const {
      agents,
      alertMessage,
      alertType,
      companyForm,
      currencies,
      isSubmitting,
      redirectToCompanies,
      showAlert,
      timeZoneTypes
    } = this.state;

    if (redirectToCompanies) {
      return <Redirect to='/white-labels'/>;
    }

    return <Row flexGrow={ 1 } className="module apidata editClient" vertical='start'>
      <Column flexGrow={ 1 }>
        <PageHeader
          title={ !viewOrEdit ? 'Add White Label' : viewOrEdit === 1 ? 'View White Label' : 'Edit White Label' }
          img={ WhiteLabelIcon }
        />
        <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
          <Column flexGrow={ 1 } vertical='start' className="panel-block">
            <Panel>
              <Panel.Heading>
                WHITE LABEL SETTINGS
              </Panel.Heading>
              <Panel.Body>
                <div className="panel-content" style={ {overflow: 'unset'} }>
                  <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end' style={ { width: '100%' } }>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                      <label> Name </label>
                      <input
                        className={ "form-control " + (companyForm.errors.has('name') ? 'error-field' : "") }
                        value={ companyForm.name || '' }
                        disabled={ this.isFieldDisabled() }
                        onChange={ (event) => this.onValueChange(event, 'name') }
                      />
                      { companyForm.errors.has('name') && <span className="error-message"> {companyForm.errors.get("name")}  </span> }
                    </Column>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                      <label> Short name </label>
                      <input
                        className={ "form-control " + (companyForm.errors.has('shortName') ? 'error-field' : "") }
                        value={ companyForm.shortName || '' }
                        disabled={ this.isFieldDisabled() }
                        onChange={ (event) => this.onValueChange(event, 'shortName') }
                      />
                      { companyForm.errors.has('shortName') && <span className="error-message"> {companyForm.errors.get("shortName")}  </span> }
                    </Column>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                      <label> Merchant Timezone </label>
                      <Select
                        name="timeZoneType"
                        className={ (companyForm.errors.has('timeZoneType') ? 'error-field' : "") }
                        value={ companyForm.timeZoneType || '' }
                        required={ true }
                        disabled={ this.isFieldDisabled() }
                        clearable={ false }
                        onChange={ (event) => this.onValueChange(event, 'timeZoneType') }
                        options={ timeZoneTypes }
                      />
                      { companyForm.errors.has('timeZoneType') && <span className="error-message"> {companyForm.errors.get("timeZoneType")}  </span> }
                    </Column>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                      <label> Provider Timezones </label>
                      <Select
                        name="defaultTimezone"
                        className={ (companyForm.errors.has('defaultTimezone') ? 'error-field' : "") }
                        value={ companyForm.defaultTimezone || '' }
                        required={ true }
                        disabled={ this.isFieldDisabled() }
                        clearable={ false }
                        onChange={ (event) => this.onValueChange(event, 'defaultTimezone') }
                        options={ this.getTimeZones() }
                      />
                      { companyForm.errors.has('defaultTimezone') && <span className="error-message"> {companyForm.errors.get("defaultTimezone")}  </span> }
                    </Column>
                  </Row>
                  <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end' style={ { width: '100%' } }>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                      <label> Payment URL </label>
                      <input
                        className={ "form-control " + (companyForm.errors.has('monolithUrl') ? 'error-field' : "") }
                        value={ companyForm.monolithUrl || '' }
                        disabled={ true }
                        onChange={ (event) => this.onValueChange(event, 'monolithUrl') }
                      />
                      { companyForm.errors.has('monolithUrl') && <span className="error-message"> {companyForm.errors.get("monolithUrl")}  </span> }
                    </Column>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                      <label> Sandbox URL </label>
                      <input
                        className={ "form-control " + (companyForm.errors.has('sandboxUrl') ? 'error-field' : "") }
                        value={ companyForm.sandboxUrl || '' }
                        disabled={ true }
                        onChange={ (event) => this.onValueChange(event, 'sandboxUrl') }
                      />
                      { companyForm.errors.has('sandboxUrl') && <span className="error-message"> {companyForm.errors.get("sandboxUrl")}  </span> }
                    </Column>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                      <label> Agent </label>
                      <Select
                        name="agentId"
                        className={ (companyForm.errors.has('agentId') ? 'error-field' : "") }
                        value={ companyForm.agentId || '' }
                        required={ true }
                        disabled={ this.isFieldDisabled() }
                        clearable={ false }
                        onChange={ (event) => this.onValueChange(event, 'agentId') }
                        options={ agents }
                      />
                      { companyForm.errors.has('agentId') && <span className="error-message"> {companyForm.errors.get("agentId")}  </span> }
                    </Column>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                      <label> Agent Program Currency </label>
                      <Select
                        name="agentProgramCurrency"
                        className={ (companyForm.errors.has('agentProgramCurrency') ? 'error-field' : "") }
                        value={ companyForm.agentProgramCurrency || '' }
                        required={ true }
                        disabled={ this.isFieldDisabled() }
                        clearable={ false }
                        onChange={ (event) => this.onValueChange(event, 'agentProgramCurrency') }
                        options={ currencies }
                      />
                      { companyForm.errors.has('agentProgramCurrency') && <span className="error-message"> {companyForm.errors.get("agentProgramCurrency")}  </span> }
                    </Column>
                  </Row>
                  <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end' style={ { width: '100%' } }>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                      <label> API Login </label>
                      <input
                        className={ "form-control " + (companyForm.errors.has('apiLogin') ? 'error-field' : "") }
                        value={ companyForm.apiLogin || '' }
                        disabled={ this.isFieldDisabled() }
                        onChange={ (event) => this.onValueChange(event, 'apiLogin') }
                      />
                      { companyForm.errors.has('apiLogin') && <span className="error-message"> {companyForm.errors.get("apiLogin")}  </span> }
                    </Column>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                      <label> API Password </label>
                      <input
                        className={ "form-control " + (companyForm.errors.has('apiPassword') ? 'error-field' : "") }
                        value={ companyForm.apiPassword || '' }
                        disabled={ this.isFieldDisabled() }
                        onChange={ (event) => this.onValueChange(event, 'apiPassword') }
                      />
                      { companyForm.errors.has('apiPassword') && <span className="error-message"> {companyForm.errors.get("apiPassword")}  </span> }
                    </Column>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                      <label> API URL </label>
                      <input
                        className={ "form-control " + (companyForm.errors.has('apiUrl') ? 'error-field' : "") }
                        value={ companyForm.apiUrl || '' }
                        disabled={ this.isFieldDisabled() }
                        onChange={ (event) => this.onValueChange(event, 'apiUrl') }
                      />
                      { companyForm.errors.has('apiUrl') && <span className="error-message"> {companyForm.errors.get("apiUrl")}  </span> }
                    </Column>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                      <label> Cascading Mode </label>
                      <Select
                        name="cascadingMode"
                        className={ (companyForm.errors.has('cascadingMode') ? 'error-field' : "") }
                        value={ companyForm.cascadingMode || '' }
                        required={ true }
                        disabled={ this.isFieldDisabled() }
                        clearable={ false }
                        onChange={ (event) => this.onValueChange(event, 'cascadingMode') }
                        options={ this.cascadingModesOptions() }
                      />
                      { companyForm.errors.has('cascadingMode') && <span className="error-message"> {companyForm.errors.get("cascadingMode")}  </span> }
                    </Column>
                  </Row>
                  <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end' style={ { width: '100%' } }>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                      <label> Master BO URL </label>
                      <input
                        className={ "form-control " + (companyForm.errors.has('masterBoUrl') ? 'error-field' : "") }
                        value={ companyForm.masterBoUrl || '' }
                        disabled={ false }
                        onChange={ (event) => this.onValueChange(event, 'masterBoUrl') }
                      />
                      { companyForm.errors.has('masterBoUrl') && <span className="error-message"> {companyForm.errors.get("masterBoUrl")}  </span> }
                    </Column>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                      <label> Merchant BO URL </label>
                      <input
                        className={ "form-control " + (companyForm.errors.has('clientBoUrl') ? 'error-field' : "") }
                        value={ companyForm.clientBoUrl || '' }
                        disabled={ false }
                        onChange={ (event) => this.onValueChange(event, 'clientBoUrl') }
                      />
                      { companyForm.errors.has('clientBoUrl') && <span className="error-message"> {companyForm.errors.get("clientBoUrl")}  </span> }
                    </Column>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                      <label> Agent BO URL </label>
                      <input
                        className={ "form-control " + (companyForm.errors.has('agentBoUrl') ? 'error-field' : "") }
                        value={ companyForm.agentBoUrl || '' }
                        disabled={ false }
                        onChange={ (event) => this.onValueChange(event, 'agentBoUrl') }
                      />
                      { companyForm.errors.has('agentBoUrl') && <span className="error-message"> {companyForm.errors.get("agentBoUrl")}  </span> }
                    </Column>
                    <Column
                      flexGrow={ 1 }
                      className="input-column empty-column"
                    >
                      <span />
                    </Column>
                  </Row>
                  <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end' style={ { width: '100%' } }>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column styled-input--square checkbox-input">
                      <div className="styled-input-single">
                        <input
                          id="providerOnOffToggleVisible"
                          type="checkbox"
                          disabled={ this.isFieldDisabled() }
                          checked={ companyForm.providerOnOffToggleVisible }
                          value={ companyForm.providerOnOffToggleVisible }
                          onChange={ (event) => this.onValueChange(event, 'providerOnOffToggleVisible') }
                        />
                        <label style={ { fontWeight: "normal" } } htmlFor="providerOnOffToggleVisible">
                          MIDs Status Controller
                        </label>
                      </div>
                    </Column>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start'
                      className="input-column styled-input--square checkbox-input">
                      <div className="styled-input-single">
                        <input
                          id="otcEnabled"
                          type="checkbox"
                          disabled={ this.isFieldDisabled() }
                          checked={ companyForm.otcEnabled }
                          value={ companyForm.otcEnabled }
                          onChange={ (event) => this.onValueChange(event, 'otcEnabled') }
                        />
                        <label style={ { fontWeight: "normal" } } htmlFor="otcEnabled">
                          Inbound Settlement
                        </label>
                      </div>
                    </Column>
                    <Column flexGrow={ 1 } vertical='start' alignSelf='start'
                      className="input-column styled-input--square checkbox-input">
                      <div className="styled-input-single">
                        <input
                          id="merchantSettingsV2"
                          type="checkbox"
                          disabled={ this.isFieldDisabled() }
                          checked={ companyForm.merchantSettingsV2 }
                          value={ companyForm.merchantSettingsV2 }
                          onChange={ (event) => this.onValueChange(event, 'merchantSettingsV2') }
                        />
                        <label style={ { fontWeight: "normal" } } htmlFor="merchantSettingsV2">
                          Merchant Settings V2
                        </label>
                      </div>
                    </Column>
                    <Column flexGrow={ 1 } vertical='end' className="input-column">
                      { viewOrEdit !== 1 ? (
                        <CustomButton
                          title="Save"
                          type="submit"
                          disabled={ isSubmitting }
                          onClick={ this.submitCompanySettings }
                        />
                      ) : (
                        <span/>
                      )}
                    </Column>
                  </Row>
                </div>
              </Panel.Body>
            </Panel>
          </Column>
        </Row>
      </Column>

      {showAlert && (
        <Alert
          show={ showAlert }
          title={ alertType }
          type={ alertType }
          text={ alertType === "success" ? `White Label successfully ${viewOrEdit === 2 ? 'updated' : 'saved'}.` : alertMessage }
          confirmButtonColor="#187EED"
          onConfirm={ this.onConfirm }
        />
      )}

    </Row>;
  }
};

export default ManageCompany;