import React, { Component } from 'react';
import Datetime from "react-datetime";
import { Column, Row } from 'simple-flexbox';

import Alert from '@sm/components/custom/Alert';
import CustomButton from "@sm/components/custom/Button";
import Form from "@sm/core/Form";
import PromptModal from '@modules/generateReport/PromptModal';
import Select from '@sm/components/custom/Select';
import Spinner from '@sm/components/Spinner';
import Table from '@sm/components/custom/Table';

import CopyIcon from '@assets/images/copy-icon.svg';
import EditIcon from '@assets/images/pencil-edit.svg';

import { crudActions } from "@sm/services/crudActions";

class DefaultCommercialSettings extends Component {
  state = {
    payinDefaultCommercials: [],
    payinDefaultCommercialsForm: [],
    payoutDefaultCommercials: [],
    payoutDefaultCommercialsForm: [],

    payinDefaultCommercialsFormFieldsBase: {
      id: "",
      currency: "",
      method: "",
      traffic: "",
      tPlusX: "",
      payinFee: "",
      settlementFee: "",
      date: ""
    },
    payinDefaultCommercialsFormRulesBase: [
      {
        name: "currency",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "method",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "traffic",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "tPlusX",
        type: "isNumber",
        rules: {
          required: true,
          min: 0
        }
      }, {
        name: "payinFee",
        type: "isNumber",
        rules: {
          required: true,
          min: 0
        }
      }, {
        name: "settlementFee",
        type: "isNumber",
        rules: {
          required: true,
          min: 0
        }
      },
      {
        name: "date",
        type: "isDate",
        rules: {
          required: true
        }
      }
    ],
  
    payoutDefaultCommercialsFormFieldsBase: {
      id: "",
      currency: "",
      method: "",
      ptPlusX: 0,
      payoutFee: "",
      date: ""
    },
    payoutDefaultCommercialsFormRulesBase: [
      {
        name: "currency",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "method",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "payoutFee",
        type: "isNumber",
        rules: {
          required: true,
          min: 0
        }
      },
      {
        name: "date",
        type: "isDate",
        rules: {
          required: true
        }
      }
    ],
    defaultDateFormat: 'DD.MM.YYYY',
    showPromptModal: false,
    changeValueMessage: '',
    isSubmittingCommercials: false,
    showAlert: false,
    alertType: "success",
    alertMessage: "",
  };

  componentDidMount() {
    const { payinDefaultCommercials, payoutDefaultCommercials } = this.props;
    this.setState({
      payinDefaultCommercials,
      payoutDefaultCommercials
    });
    this.populateDefaultCommercialForm(payinDefaultCommercials, "payinDefaultCommercials");
    this.populateDefaultCommercialForm(payoutDefaultCommercials, "payoutDefaultCommercials");
  }

  getTableData = () => {
    const { panel } = this.props;
    const originalData = this.state[panel.id];
    const forms = this.state[panel.form];
   
    const rowsData = [];
    forms.forEach((commercialRow, rowIndex) => {
      const data = commercialRow.data();
      if (commercialRow.id) {
        if (commercialRow.isEditing) {
          panel.columns.forEach((column) => {
            if (column.type === 'input') {
              data[column.value] = this.getTableInputElement(data[column.value], column.value, panel.form, rowIndex);
            } else if (column.type === 'button') {
              data[column.value] = this.getActionButton(commercialRow, rowIndex, commercialRow.isEditing);
            } else if (column.type === 'date') {
              data[column.value] = this.getTableDateElement(Datetime.moment(data[column.value], 'YYYY-MM-DD').format('DD.MM.YYYY'), column.value, panel.form, rowIndex);
            }
          });
          data.isEditing = true;
        } else {
          if (Datetime.moment().isAfter(Datetime.moment(commercialRow.date, 'YYYY-MM-DD'))) {
            data.isDisabledRow = true;
          }
          data.action = this.getActionButton(commercialRow, rowIndex, commercialRow.isCopying);
        }
      }
      rowsData.push(data);
    });
  
    if (forms.length > originalData.length) {
      this.addInputField(rowsData, forms.length - originalData.length);
    }
    rowsData.push(this.getPlusButtonRow());
    return rowsData;
  };

  getBaseCommercialForm = (panelForm) => {
    const baseFields = this.state[panelForm + "FieldsBase"];
    const baseRules = this.state[panelForm + "RulesBase"];
    return new Form({...baseFields}, [...baseRules]);
  };
  
  populateDefaultCommercialForm = (originlData, panelId) => {
    const { defaultDateFormat } = this.state;
    const panel = this.getPanelById(panelId);
    const columns = panel.columns;
  
    const settingsForm = this.state[panel.form];
  
    const updatedSettingsForm = [...settingsForm, ...originlData.map((values, formIndex) => {
      const settingsFormBase = this.getBaseCommercialForm(panel.form);
      columns.forEach(column => {
        if (column.type === 'date') {
          settingsFormBase[column.value] = Datetime.moment(values[column.value], defaultDateFormat).format('YYYY-MM-DD');
        } else if (column.type === 'button') {
          settingsFormBase[column.value] = this.getActionButton(settingsFormBase);
        } else {
          settingsFormBase[column.value] = values[column.value];
        }
      });
      settingsFormBase.id = values.id;
      return settingsFormBase;
    })];
    this.setState({
      [panel.form]: updatedSettingsForm
    });
  };
  
  onDateDoubleClick = (row, column, formIndex) => {
    const { panel } = this.props;
    const settingsForms = this.state[panel.form];
    const isCurrentFormEditing = settingsForms.some(form => form.updateId === formIndex);
    if (column.type === 'date' && row.id && this.isDateBeforeToday(row.date) && !isCurrentFormEditing) {
      this.setState({
        showPromptModal: true,
        changeValueMessage: `Do you want to update the default commercial settings?`,
        action: () => this.onAddItemChange(formIndex),
      });
    }
  };

  isDateBeforeToday = (compareDate) => {
    return Datetime.moment(compareDate, 'YYYY-MM-DD').isBefore(Datetime.moment());
  };
  
  addInputField = (rowsData, numberOfNewRows) => {
    const { panel } = this.props;
    const newRows = rowsData.slice(-numberOfNewRows);
    const forms = this.state[panel.form];
    const columns = panel.columns;
  
    newRows.forEach((elem, index) => {
      const formId = rowsData.length - numberOfNewRows + index;
      columns.forEach((column) => {
        if (column.type === "select") {
          elem[column.value] = this.getTableSelectElement(forms[formId][column.value], column.value, panel.form, formId,
            this.getOptions(forms, formId, column),
            this.isDisabled(column.canBeDisabled, forms[formId].disabled, column.value, forms, formId));
        } else if (column.type === "input") {
          elem[column.value] = this.getTableInputElement(forms[formId][column.value], column.value, panel.form, formId);
        } else if (column.type === "button") {
          elem[column.value] = this.getActionButton(forms[formId], formId);
        } else {
          elem[column.value] = !elem.id && column.value !== 'empty' ? this.getTableDateElement(forms[formId][column.value], column.value, panel.form, formId) : forms[formId][column.value] ;
        }
      });
    });
  };
  
  getOptions = (forms, formId, column) => {
    const { panel } = this.props;
    const options = this.props[column.options];
    return forms[formId].disabled ? options : this.filterOptions(column.options, column.value, panel.form, formId);
  };
  
  isDisabled = (canBeDisabled, isDisabled, fieldName, forms, formId) => {
    return (canBeDisabled && isDisabled)
        || (fieldName === "method" && !forms[formId].currency)
        || (fieldName === "traffic" && (!forms[formId].currency || !forms[formId].method));
  };
  
  getTableSelectElement = (filedValue, fieldName, form, formId, options, disabled) => {
    return (
      <div className={ 'select-short ' + (this.state[form][formId].errors.has(fieldName) ? 'td-error' : '') }>
        <Select
          id={ fieldName }
          name={ fieldName }
          value={ filedValue || "" }
          classNamePrefix='select-short'
          required={ true }
          clearable={ false }
          disabled={ disabled }
          onChange={ (value) => this.onValueChange(value, fieldName, form, formId) }
          options={ options }
        />
      </div>
    );
  };
  
  filterOptions = (optionsName, fieldName, formName, formIndex) => {
    const forms = this.state[formName];
    const options = this.props[optionsName];
    if (fieldName === "method" && forms[formIndex].currency) {
      return options.filter(option => forms.filter((elem, idx) => idx !== formIndex && elem.currency === forms[formIndex].currency && elem.method === option.value).length < (formName === "payinDefaultCommercialsForm" ? 2 : 1));
    }
    if (fieldName === "traffic" && forms[formIndex].currency && forms[formIndex].method) {
      return options.filter(option => forms.some((form, idx) => idx !== formIndex && form.currency === forms[formIndex].currency && form.method === forms[formIndex].method && form.traffic === option.value) < 1);
    }
    return options;
  };
  
  getTableInputElement = (filedValue, fieldName, form, formId) => {
    return (
      <input
        className={ 'table-input ' + (this.state[form][formId].errors.has(fieldName) ? 'td-error' : '') }
        type={ "text" }
        value={ filedValue }
        onChange={ (event) => this.onValueChange(event, fieldName, form, formId) }
      />
    );
  };
  
  getTableDateElement = (fieldValue, fieldName, formName, formId) => {
    const { defaultDateFormat } = this.state;
    const form = this.state[formName][formId];
    let compareDate = Datetime.moment().utc();
    if (typeof form.updateId === "number") {
      compareDate = Datetime.moment(this.state[formName][form.updateId].date).format(defaultDateFormat);
    }
    return (
      <div className={ "table-date " + (form && form.errors.has(fieldName) ? 'td-error' : '') }>
        <Datetime
          type="text"
          timeFormat={ false }
          dateFormat={ defaultDateFormat }
          value={ fieldValue }
          isValidDate={ (event) => this.isDateValid(event, compareDate) }
          onChange={ (event) => this.handleDateChange(event, fieldName, formName, formId) }
          required={ true }
        />
      </div>
    );
  };
  
  isDateValid = (current, oldDate) => {
    return current.isAfter(Datetime.moment().utc());
  };

  handleDateChange = (event, fieldName, formName, formId) => {
    const forms = this.state[formName];
    const form = forms[formId];
    form[fieldName] = event;
    this.setState({
      [formName]: forms
    });
  };
  
  getActionButton = (form, formIndex, disabled) => {
    if (form.id) {
      if (this.isDateBeforeToday(form.date)) {
        return (
          <button
            className="delete edit copy"
            disabled={ disabled }
            onClick={ () => this.onAddItemChange(formIndex) }
          >
            <img src={ CopyIcon } alt="copy" />
          </button>
        );
      } else {
        return (
          <button
            className="delete edit"
            disabled={ disabled }
            onClick={ () => this.onEditItemChange(formIndex) }
          >
            <img src={ EditIcon } alt="edit" />
          </button>
        );
      }
    }
    return (
      <button className="delete" onClick={ () => this.onDeleteItemChange(formIndex) }> - </button>
    );
  };
  
  getPlusButton = () => {
    return (
      <button className="add" onClick={ () => this.onAddItemChange() }>
        <span className="plus"> + </span>
      </button>
    );
  };
  
  getPlusButtonRow = () => {
    const { panel } = this.props;
    const columns = panel.columns;
    const lastColumnKey = columns[columns.length - 1].value;
  
    return Object.fromEntries(
      columns.map(col => [col.value, col.value === lastColumnKey ? this.getPlusButton() : ""])
    );
  };
  
  onAddItemChange = (formIndex) => {
    const { panel } = this.props;
    const settingsForms = this.state[panel.form];
    const currentForm = settingsForms[formIndex];
    const settingsFormBase = this.getBaseCommercialForm(panel.form);
    const newDate = Datetime.moment().add(1, 'days');
    if (typeof formIndex === "number") {
      settingsFormBase.method = currentForm.method;
      settingsFormBase.currency = currentForm.currency;
      settingsFormBase.traffic = currentForm.traffic;
      settingsFormBase.date = newDate;
      settingsFormBase.disabled = true;
      settingsFormBase.updateId = formIndex;
      currentForm.isCopying = true;
    } else {
      settingsFormBase.date = newDate;
    }
    settingsForms.push(settingsFormBase);
    this.setState({
      [panel.form]: settingsForms
    });
  };

  onEditItemChange = (formIndex) => {
    const { panel } = this.props;
    const settingsForms = this.state[panel.form];
    settingsForms[formIndex].isEditing = true;

    this.setState({
      [panel.form]: settingsForms
    });
  };
  
  onDeleteItemChange = (formIndex) => {
    const { panel } = this.props;
    const settingsForms = this.state[panel.form];
    const formToDelete = settingsForms[formIndex];
    if (typeof formToDelete.updateId === 'number') {
      settingsForms[formToDelete.updateId].isCopying = false;
    }
    settingsForms.splice(formIndex, 1);
    this.setState({
      [panel.form]: settingsForms
    });
  };
  
  getPanelById = (id) => {
    const { panels } = this.props;
    return panels.find(panel => panel.id === id);
  };

  onValueChange = (event, fieldName, formName, id) => {
    const originalForm = this.state[formName];
    const form = typeof id !== 'number' ? originalForm : originalForm[id];
    const isNumber = form.fieldRules.find(rule => rule.name === fieldName).type === "isNumber";
    if (event.value) {
      form[fieldName] =
         isNumber && !isNaN(parseFloat(event.value)) ? parseFloat(event.value) : event.value;
    } else if (event.target) {
      let value = event.target.value;
      value = value.replace(/[^0-9.]/g, "");
      if (value.indexOf(".") > -1) {
        if (this.isPrecise(value)) {
          if (value.indexOf(".") < value.length - 1) {
            form[fieldName] = isNumber && !isNaN(parseFloat(value)) ? parseFloat(value) : value;
          } else {
            form[fieldName] = value;
          }
        }
      } else {
        form[fieldName] = isNumber && !isNaN(parseFloat(event.target.value)) ? parseFloat(event.target.value) : event.target.value;
      }
    } else {
      form[fieldName] = event.map(elem => elem.value);
    }

    this.setState({
      [formName]: originalForm
    });
  };

  isPrecise = (num) => {
    return String(num).split(".")[1]?.length <= 2;
  };
  
  submitDefaultCommercialSettings = (e) => {
    e.preventDefault();
    const { panel } = this.props;
    const { defaultDateFormat } = this.state;
    const commercialRows = this.state[panel.form];
    let isFormsValid = commercialRows.length;
    if (commercialRows.length > 0) {
      commercialRows.forEach(form => {
        form.isFormValid();
        if (!form.isFormValid()) {
          isFormsValid = false;
        }
      });
    }
    this.setState({
      [panel.form]: commercialRows
    });
    if (isFormsValid) {
      this.setState({
        isSubmittingCommercials: true
      });
      const data = [];
      const isPayin = panel.id === 'payinDefaultCommercials';
      commercialRows.forEach(row => {
        data.push({
          id: row.id,
          clientId: this.props.clientId,
          currency: row.currency,
          method: row.method,
          ...(row.traffic && { traffic: row.traffic }),
          ...(typeof row.tPlusX === 'number' && { tPlusX: row.tPlusX }),
          ...(!isPayin && { ptPlusX: row.ptPlusX }),
          ...(typeof row.payinFee === 'number' && { payinFee: row.payinFee }),
          ...(typeof row.payoutFee === 'number' && { payoutFee: row.payoutFee }),
          ...(typeof row.settlementFee === 'number' && { settlementFee: row.settlementFee }),
          date: Datetime.moment(row.date).format(defaultDateFormat),
        });
      });
      const type = isPayin ? 'payin' : 'payout';
      crudActions.post(`v1/default-commercials/${type}`, data)
        .then(response => {
          this.setState({
            showAlert: true,
            alertType: "success",
            alertMessage: `Successfully saved default ${type} commercial settings.`,
            isSubmittingCommercials: false
          });
        })
        .catch(err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isSubmittingCommercials: false
            });
          }
        });
    }
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  handleConfirmPromptModal = () => {
    const { action } = this.state;
    this.setState({
      showPromptModal: false,
      changeValueMessage: ""
    });
    action();
  };

  handleClosePromptModal = () => {
    this.setState({
      showPromptModal: false,
      changeValueMessage: ""
    });
  };

  render() {
    const { loadingGeneralSettings, panel, viewOrEdit } = this.props;
    const {
      alertMessage,
      alertType,
      changeValueMessage,
      isSubmittingCommercials,
      showAlert,
      showPromptModal
    } = this.state;
    
    return (
      <>
        {loadingGeneralSettings ? (
          <div style={ { width: "100%", height: "200px", display: "flex", alignItems: "center", justifyContent: "center" } }>
            <Spinner smallContainer={ true } />
          </div>
        ) : (
          <div className="default-client-settings">
            <Table
              columns={ panel.columns }
              className="default-commercials"
              data={ this.getTableData() }
              isStriped={ false }
              headerColor={ "#223679" }
              headerTextColor={ "#FFFFFF" }
              withSort={ false }
              onDoubleClick={ (dataElem, column, index) => this.onDateDoubleClick(dataElem, column, index) }
            />
            <Row flexGrow={ 1 } horizontal='end' wrap={ true } vertical='start'>
              { viewOrEdit !== 1 && !loadingGeneralSettings && (
                <Column flexGrow={ 0 } vertical='end' className="input-column">
                  <CustomButton
                    title="Save"
                    type="submit"
                    disabled={ isSubmittingCommercials }
                    onClick={ (e) => this.submitDefaultCommercialSettings(e) }
                  />
                </Column>
              )}
            </Row>
          </div>
        )}

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}
        
        <PromptModal
          title="Update default settings"
          message={ changeValueMessage }
          handleConfirm={ this.handleConfirmPromptModal }
          handleClose={ this.handleClosePromptModal }
          isLoading={ this.state.changeActivePropertyLoading }
          showModal={ showPromptModal }
        />
      </>
    );
  }
}

export default DefaultCommercialSettings;