export const trxExpirationTimeOptions = [{
  label: "10 minutes",
  value: 10
}, {
  label: "30 minutes",
  value: 30
}, {
  label: "1 hour",
  value: 60
}, {
  label: "2 hours",
  value: 120
}, {
  label: "24 hour",
  value: 1440
}, {
  label: "2 days",
  value: 2880
}, {
  label: "3 days",
  value: 4320
}, {
  label: "14 days",
  value: 20160
}, {
  label: "30 days",
  value: 43200
}, {
  label: "Never",
  value: 0
}];