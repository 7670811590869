import React, { Component } from "react";
import { Panel } from "react-bootstrap";
import { Column, Row } from "simple-flexbox";

import CustomButton from "@sm/components/custom/Button";
import Select from "@sm/components/custom/Select";

class UpdateSettlementComponent extends Component {
  render() {
    const {
      controls,
      fieldsMap,
      filterFields,
      getFilteredStatuses,
      getValue,
      lockButton,
      onChange,
      onSave,
      savedDataList,
      selectedForm,
      writePermission
    } = this.props;

    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title>DATA ENTRIES</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <div
            className="panel-content amount-form"
            style={ { overflow: "unset" } }
          >
            {savedDataList.map((elem, i) => (
              <Row
                key={ i }
                wrap={ true }
                horizontal="start"
                vertical="end"
                style={ { width: "100%" } }
              >
                {fieldsMap.map((rowArray, index) => (
                  <Row
                    key={ index }
                    wrap={ true }
                    horizontal="start"
                    vertical="end"
                    style={ { width: "100%" } }
                  >
                    {filterFields(rowArray, elem).map(
                      (fieldElem, elemIndex) => (
                        <Column
                          key={ `${fieldElem.name}-${elemIndex}` }
                          flexGrow={ 1 }
                          className="input-column"
                        >
                          <label> {fieldElem.label} </label>
                          {fieldElem.fieldType === "select" ? (
                            <Select
                              id={ fieldElem.name }
                              name={ fieldElem.name }
                              value={ elem[fieldElem.name] || controls.settlementStatus || "" }
                              required={ true }
                              clearable={ false }
                              disabled={ fieldElem.disabled }
                              onChange={ (value) => onChange(value, fieldElem.name, elem.id) }
                              options={ getFilteredStatuses(elem[fieldElem.items], fieldElem) }
                            />
                          ) : !fieldElem.fieldType ? (
                            <span />
                          ) : (
                            <input
                              id={ fieldElem.name }
                              name={ fieldElem.name }
                              type="text"
                              value={ getValue( elem[fieldElem.name], selectedForm[fieldElem.name]) }
                              className={ `form-control ${selectedForm.errors.has(fieldElem.name) ? "error-field" : ""}` }
                              disabled={ fieldElem.disabled }
                              style={ { paddingLeft: 10 } }
                              onChange={ (value) => onChange(value, fieldElem.name) }
                            />
                          )}
                        </Column>
                      ),
                    )}
                  </Row>
                ))}
              </Row>
            ))}
            {writePermission && (
              <Row
                wrap={ true }
                horizontal="start"
                vertical="end"
                style={ { width: "100%" } }
              >
                {[0, 1, 2].map((column) => (
                  <Column
                    key={ column }
                    flexGrow= { 1 }
                    className="input-column empty-column"
                  >
                    <span />
                  </Column>
                ))}
                <Column flexGrow={ 1 } className="input-column fullwidth-button">
                  <CustomButton
                    title="Save"
                    type="button"
                    disabled={ lockButton }
                    onClick={ () => onSave() }
                  />
                </Column>
              </Row>
            )}
          </div>
        </Panel.Body>
      </Panel>
    );
  }
}

export default UpdateSettlementComponent;