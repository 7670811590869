import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel, Well } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

import Alert from "@sm/components/custom/Alert";
import CustomButton from "@sm/components/custom/Button";
import Modal from '@sm/components/custom/Modal';
import Multiselect from "@sm/components/custom/customMultiselect";
import PromptModal from '@modules/generateReport/PromptModal';
import Select from "@sm/components/custom/Select";
import Spinner from '@sm/components/Spinner';
import Table from "@sm/components/custom/Table";

import '@assets/css/mids.css';
import MidsIcon from '@assets/images/mids.png';

import { crudActions } from "@sm/services/crudActions";
import { FETCH_PERMISSIONS } from '@sm/actions/types';
const store =  require('@sm/reducers/index');

class Mids extends Component {
  state = {
    psp: [],
    countries: [],
    currency: [],
    company: [],
    paymentMethod: [],
    sellMethod: [],
    payoutMethod: [],
    companiesClients: [],
    midCategories: [],
    trafficTypes: [{
      label: "FTD",
      value: "FTD"
    }, {
      label: "Mixed",
      value: "MIXED"
    }, {
      label: "Trusted",
      value: "TRUSTED"
    }],
    filterOn: false,

    controls: {
      psp: "",
      name: "",
      company: "",
      country: "",
      type: "",
      merchant: "",
      midCategories: [],
      currency: "",
      trafficTypes: ["FTD", "MIXED", "TRUSTED"]
    },

    mids: [],
    merchantOptions: [],

    midsLinks: [{
      name: "ADD MIDs",
      url: "/add-mid",
    }],

    showPromptModal: false,
    changeValueMessage: "",
    editableField: "",
    editableMidId: "",
    editableFieldValue: "",
    originalEditableFieldValue: "",

    showModal: false,
    modalData: "",

    filteredMids: [],
    access: [],
    isLoading: true,
    isLoadingTable: false,
    isBurgerOpen: false,
    showAlert: false,
    alertType: "success",
    alertMessage: "",
    cloningMidId: null
  };

  node = null;
  subscribeFunction = null;
  burgerNode = null;

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener("keydown", this.handleEscapeClick, false);
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    Promise.all([
      crudActions.get('v1/adminpanel/lookups'),
      crudActions.get(`v1/companies/clients`),
      crudActions.get("v1/psp"),
      crudActions.get('v1/mids')
    ]).then(
      data => {
        const dropdowns = data[0];
        const companiesData = data[1];
        const psps = data[2];
        const mids = data[3];

        if (dropdowns && companiesData && psps && mids) {
          const companies = companiesData.map(elem => elem.company);
          companies.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));
          this.setState({
            countries: dropdowns.country,
            currency: dropdowns.currency,
            paymentMethod: dropdowns.buyMethods,
            sellMethod: dropdowns.sellMethods,
            payoutMethod: dropdowns.payoutMethods,
            company: companies,
            controls: Object.assign(this.state.controls, { company: companies[0] ? companies[0].value : "" }),
            companiesClients: companiesData.reduce((accumulator, currentElem) => {
              accumulator[currentElem.company.value] = currentElem.clients.map(elem => elem.client);
              return accumulator;
            }, {}),
            psp: psps.map(elem => {
              return {
                value: elem.id,
                label: elem.name,
                companyIds: elem.companyIds
              };
            }),
            mids: mids.map(elem => {
              return {
                ...elem,
                midName: `${elem.midName} (${elem.id})`
              };
            }),
            isLoading: false
          }, () => {
            const { controls } = this.state;
            if (controls.company) {
              if (companies[0]) {
                this.onValueChange(companies[0], 'company');
              }
            }
          });
        }
      }
    );
  };

  handleClick = (e) => {
    if ((this.node && this.node.contains(e.target)) || (this.burgerNode && this.burgerNode.contains(e.target))) {
      return;
    }

    this.handleClickOutside();
  };

  handleClickOutside = () => {
    if (!this.state.isBurgerOpen) {
      return;
    }
    this.setState({
      isBurgerOpen: false
    });
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener("keydown", this.handleEscapeClick, false);
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  handleEscapeClick = (event) => {
    if (event.keyCode === 27 && this.state.editableMidId !== "") {
      this.setState({
        editableField: "",
        editableMidId: "",
        editableFieldValue: "",
        originalEditableFieldValue: ""
      });
    }
  };

  onValueChange = (event, field) => {
    let { merchantOptions } = this.state;
    const { controls, companiesClients } = this.state;
    if (!event) {
      controls[field] = event;
    } else if (event.target) {
      controls[field] = event.target.value;
    } else if (event.value) {
      controls[field] = event.value;
    } else {
      controls[field] = event.map(elem => elem.value);
    }

    if (field === "company") {
      const company = controls[field];
      controls.midCategories = [];
      controls.merchant = "";
      controls.psp = "";
      merchantOptions = [];
      if (company) {
        merchantOptions = merchantOptions.concat(companiesClients[company]);
        this.loadCategories(company);
      }
    }

    this.setState({
      controls,
      merchantOptions
    });
  };

  loadCategories = (companyId) => {
    crudActions.get(`v1/adminpanel/company/${companyId}`).then(
      (dropdowns) => {
        if (dropdowns) {
          this.setState({
            midCategories: dropdowns.storedLookups.midCategories.map(
              (elem) => {
                return {
                  value: elem,
                  label: elem
                };
              }
            )
          });
        }
      }
    );
  };

  mapSelectedItems = (type) => {
    const field = this.state.controls[type];
    const lookup = this.state[type];
    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  onChangeFilter = () => {
    this.filterMids();
  };

  filterMids = () => {
    this.setState({
      isLoadingTable: true
    });
    crudActions.get('v1/mids')
      .then(response => {
        if (response) {
          this.setState({
            mids: response.map(elem => {
              return {
                ...elem,
                midName: `${elem.midName} (${elem.id})`
              };
            }),
            isLoadingTable: false
          }, () => {
            this.getFilteredMids();
          });
        }
      })
      .catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isLoadingTable: false
            });
          }
        }
      );
  };

  getFilteredMids = () => {
    const { mids, controls } = this.state;
    const filteredMids = mids.filter(elem => {
      let startCondition = elem;
      if (controls.psp) {
        startCondition = startCondition && ((elem.pspId === controls.psp) || (elem.payoutPspId && elem.payoutPspId === controls.psp));
      }

      if (controls.name) {
        startCondition = startCondition && elem.midName.toLowerCase().includes(controls.name.toLowerCase());
      }

      if (controls.country) {
        startCondition = startCondition && elem.countries.some(country => country === controls.country);
      }

      if (controls.currency) {
        startCondition = startCondition && elem.currency === controls.currency;
      }

      if (controls.type) {
        startCondition = startCondition && elem.paymentMethodType === controls.type;
      }

      if (controls.trafficTypes) {
        startCondition = startCondition && controls.trafficTypes.includes(elem.traffic);
      }

      if (controls.company) {
        startCondition = startCondition && elem.companyId.toString() === controls.company.toString();
      }

      const merchantOption = controls.merchant;
      if (merchantOption) {
        if (merchantOption === "multi") {
          startCondition = startCondition && elem.merchants.length > 1;
        } else if (merchantOption === "no_merchant") {
          startCondition = startCondition && elem.merchants.length === 0;
        } else {
          startCondition = startCondition && elem.merchants.find(merchant => merchant.toString() === merchantOption.toString());
        }
      }

      if (controls.midCategories.length > 0) {
        startCondition = startCondition && controls.midCategories.some(midCategory => midCategory === elem.category);
      }

      return startCondition;
    });

    this.setState({
      filteredMids,
      filterOn: true
    });
  };

  getMidInfo = (midData, infoType) => {
    const midInfo = this.state[infoType].find(elem => elem.value === midData);
    if (!midInfo) {
      return "";
    }

    return midInfo.label;
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  tableColumns = () => {
    const columns = [{
      value: "midName",
      label: "MID name"
    }, {
      value: "merchants",
      label: "Merchant"
    }, {
      value: "pspId",
      label: "Providers"
    }, {
      value: "traffic",
      label: "Traffic"
    }, {
      value: "paymentMethod",
      label: "Payment Method"
    }, {
      value: "payoutPaymentMethod",
      label: "Payout Method"
    }, {
      value: "countries",
      label: "Countries"
    }, {
      value: "minDeposit",
      label: "Min Deposit",
      isEditable: true
    }, {
      value: "maxDeposit",
      label: "Max Deposit",
      isEditable: true
    }, {
      value: "minPayout",
      label: "Min Payout",
      isEditable: true
    }, {
      value: "maxPayout",
      label: "Max Payout",
      isEditable: true
    }, {
      value: "currency",
      label: "Currency"
    }, {
      value: "enabled",
      label: "Payin Status",
      centerAligned: true
    }, {
      value: "payoutEnabled",
      label: "Payout Status",
      centerAligned: true
    }, {
      value: "callbackUrl",
      label: "Callback URLs",
      centerAligned: true,
      className: "open-modal-btn",
      buttonLabel: "View",
      isButton: true,
      triggerEvent: (data) => this.onOpenModalClick(data),
      isButtonDisabled: () => { return false; },
      isButtonLoading: () => { return false; }
    }, {
      value: "id",
      label: "View",
      centerAligned: true,
      className: "btn view-mid",
      route: "/view-mid",
      isAction: true
    }];

    if (this.checkPageAccess("MIDS_EDIT")) {
      columns.push({
        value: "id",
        label: "Clone",
        centerAligned: true,
        className: "btn edit-mid clone-mid",
        buttonLabel: "Clone",
        isButton: true,
        triggerEvent: (data) => this.onCloneMidClick(data.id),
        isButtonDisabled: () => { return !!this.state.cloningMidId; },
        isButtonLoading: (data) => { return data.id === this.state.cloningMidId; }
      }, {
        value: "id",
        label: "Edit",
        centerAligned: true,
        className: "btn edit-mid",
        route: "/edit-mid",
        isAction: true
      });
    }

    return columns;
  };

  tableData = () => {
    const { filteredMids, psp, company, companiesClients } = this.state;

    return filteredMids.map(mid => {
      const foundPsp = psp.find(elem => elem.value === mid.pspId);
      const foundPayoutPsp = mid.payoutPspId ? psp.find(elem => elem.value === mid.payoutPspId) : "";
      const pspsLabels = `${foundPsp ? foundPsp.label : ""}${foundPayoutPsp && foundPsp.label !== foundPayoutPsp.label ? ', ' + foundPayoutPsp.label : ""}`;
      const foundCompany = company.find(elem => elem.value.toString() === mid.companyId.toString());
      const companyMerchants = companiesClients[foundCompany.value] || [];
      const countriesLabel = mid.countries.length === 0 ? "No countries." :
        mid.countries.length === 1 ? this.getMidInfo(mid.countries[0], "countries") : "Multiple Countries";
      const merchants = mid.merchants || [];

      return Object.assign({}, mid, {
        pspId: pspsLabels,
        merchants: merchants.length === 0 ? "No merchants" :
          merchants.length === 1 ? companyMerchants.find(merchant => merchant.value.toString() === merchants[0].toString()).label :
            "Multi",
        companyId: foundCompany ? foundCompany.label : "",
        paymentMethod: this.getMidInfo(mid.paymentMethod, "paymentMethod"),
        payoutPaymentMethod: mid.payoutPaymentMethod ? this.getMidInfo(mid.payoutPaymentMethod, "payoutMethod") : "",
        countries: countriesLabel,
        currency: this.getMidInfo(mid.currency, "currency"),
        enabled: mid.enabled ? "On" : "Off",
        payoutEnabled: mid.payoutEnabled ? "On" : "Off"
      });
    });
  };

  onBurgerClick = () => {
    this.setState({
      isBurgerOpen: !this.state.isBurgerOpen
    });
  };

  getFilteredCurrency = () => {
    const { mids, currency, controls } = this.state;
    if (!controls.company) {
      return [];
    }
    const filteredCurrency = mids.filter(midElem => midElem.companyId.toString() === controls.company.toString()).map(midElem => midElem.currency);
    return currency.filter(elem1 => filteredCurrency.find(elem2 => elem1.value === elem2));
  };

  getFilteredPsps = () => {
    const { psp, controls } = this.state;
    if (!controls.company) {
      return [];
    }
    return psp.filter(pspElem => pspElem.companyIds.includes(parseInt(controls.company, 10)));
  };

  onDoubleClick = (row, column) => {
    if (column.isEditable && this.checkPageAccess("MIDS_EDIT")) {
      if (((column.value === "minPayout" || column.value === "maxPayout") && row.payoutPspId && row.payoutPaymentMethod) ||
        column.value === "minDeposit" || column.value === "maxDeposit") {
        this.setState({
          editableField: column.value,
          editableMidId: row.id,
          editableFieldValue: row[column.value],
          originalEditableFieldValue: row[column.value]
        });
      }
    }
  };

  onEditableInputChange = (value) => {
    this.setState({
      editableFieldValue: value
    });
  };

  handleEnterPress = (event) => {
    const { editableField, editableFieldValue, originalEditableFieldValue } = this.state;
    if ((event.key === "Enter" && editableFieldValue === originalEditableFieldValue) || event.key === "Escape") {
      this.setState({
        editableField: "",
        editableMidId: "",
        editableFieldValue: "",
        originalEditableFieldValue: ""
      });
      return;
    } else if (event.key === "Enter" && editableFieldValue !== originalEditableFieldValue) {
      this.setState({
        showPromptModal: true,
        changeValueMessage: `Do you want to change the ${editableField}?`
      });
    }
  };

  handleConfirmPromptModal = () => {
    const { editableField, editableFieldValue, editableMidId, filteredMids } = this.state;

    const editableMid = filteredMids.find(mid => mid.id === editableMidId);

    if (editableMid) {
      crudActions.patch(`v1/mids/${editableMidId}`, {
        [editableField]: editableFieldValue
      }).then(() => {
        editableMid[editableField] = editableFieldValue;
        this.setState({
          filteredMids,
          showPromptModal: false,
          editableField: "",
          editableMidId: "",
          editableFieldValue: "",
          originalEditableFieldValue: "",
          showAlert: true,
          alertType: "success",
          alertMessage: `Mid ${editableField} value has been updated!`,
        });
      }).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
            });
          }
        }
      );
    }
  };

  handleClosePromptModal = () => {
    this.setState({
      showPromptModal: false,
      changeValueMessage: ""
    });
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  onOpenModalClick = (data) => {
    this.setState({
      showModal: true,
      modalData: (
        <>
          <div>Payin</div>
          <Well bsSize="small">{ data.callbackUrl }</Well>
          {data.payoutCallbackUrl && (
            <>
              <div>Payout</div>
              <Well bsSize="small">{ data.payoutCallbackUrl }</Well>
            </>
          )}
        </>
      )
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      modalData: ""
    });
  };

  onCloneMidClick = (midId) => {
    if (!midId) return;
    this.setState({
      cloningMidId: midId
    });
    crudActions.get(`v1/mids/${midId}`)
      .then(midData => {
        crudActions.post("v1/mids/clone", midData).then(
          (mid) => {
            window.location.href = `/edit-mid/${mid.id}`;
          }
        ).catch(
          err => {
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message,
                cloningMidId: null
              });
            }
          }
        );
      })
      .catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              cloningMidId: null
            });
          }
        }
      );
  };

  render() {
    const {
      company,
      controls,
      countries,
      editableField,
      editableFieldValue,
      editableMidId,
      filterOn,
      isBurgerOpen,
      isLoading,
      isLoadingTable,
      merchantOptions,
      midCategories,
      midsLinks,
      modalData,
      showAlert,
      alertType,
      alertMessage,
      showModal,
      trafficTypes
    } = this.state;

    const columns = this.tableColumns();

    return (
      <Row flexGrow={ 1 } className="module mids" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ {paddingLeft: 15} }>
                <img src={ MidsIcon } alt="" style={ {marginRight: 10} }/>
                MIDs
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                { this.checkPageAccess("MIDS_EDIT") && <Column horizontal='end' style={ {paddingRight: 15} }>
                  <Row horizontal='end' vertical='center' style={ {color: '#ccc', fontSize: '12px'} }>
                    <NavLink to={ `/add-mid` } className="btn add-deposit-button">
                      ADD MIDs
                    </NavLink>
                    <div ref={ node => this.burgerNode = node } className={ "burger-container " + (isBurgerOpen ? "change" : "") } style={ { float: "right" } }  onClick={ this.onBurgerClick }>
                      <div className="burger-bar1"></div>
                      <div className="burger-bar2"></div>
                      <div className="burger-bar3"></div>
                    </div>
                    { isBurgerOpen && <div ref={ node => this.node = node } className="burger-content">
                      { midsLinks.map((link, i) => {
                        return <div key={ i } className="link-item-container">
                          <NavLink to={ link.url } className="link-item" onClick={ () => this.handleClickOutside() }> {link.name} </NavLink>
                        </div>;
                      }) }
                    </div>
                    }
                  </Row>
                </Column> }
              </Row>
            </Column>
          </Row>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    SEARCH MIDs
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { isLoading ? (
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  ) : (
                    <div className="panel-content" style={ {overflow: 'unset'} }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> White Label </label>
                          <Select
                            id="company"
                            name="company"
                            value={ controls.company || '' }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) => this.onValueChange(value, 'company') }
                            options={ company }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Merchant </label>
                          <Select
                            id="merchant"
                            name="merchant"
                            value={ controls.merchant || '' }
                            required={ true }
                            clearable={ true }
                            onChange={ (value) => this.onValueChange(value, 'merchant') }
                            options={ merchantOptions }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Provider </label>
                          <Select
                            id="psp"
                            name="psp"
                            value={ controls.psp || '' }
                            required={ true }
                            clearable={ true }
                            onChange={ (value) => this.onValueChange(value, 'psp') }
                            options={ this.getFilteredPsps() }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Currency </label>
                          <Select
                            id="сurrency"
                            name="сurrency"
                            value={ controls.currency || '' }
                            required={ true }
                            clearable={ true }
                            onChange={ (value) => this.onValueChange(value, 'currency') }
                            options={ this.getFilteredCurrency() }
                          />
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Country </label>
                          <Select
                            id="country"
                            name="country"
                            value={ controls.country || '' }
                            required={ true }
                            clearable={ true }
                            onChange={ (value) => this.onValueChange(value, 'country') }
                            options={ countries }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Traffic Type </label>
                          <Multiselect
                            selectedItems={ this.mapSelectedItems("trafficTypes") }
                            disabled={ !controls.company }
                            items={ trafficTypes }
                            type={ "trafficTypes" }
                            onChange={ this.onValueChange }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Category </label>
                          <Multiselect
                            selectedItems={ this.mapSelectedItems("midCategories") }
                            disabled={ !controls.company }
                            items={ midCategories }
                            type={ "midCategories" }
                            onChange={ this.onValueChange }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> MID Names </label>
                          <input
                            className="form-control"
                            value={ controls.name || '' }
                            onChange={ (event) => this.onValueChange(event, 'name') }/>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } className="input-column fullwidth-button">
                          <CustomButton
                            title="Search"
                            type="submit"
                            disabled={ isLoadingTable }
                            onClick={ () => this.onChangeFilter() }
                          />
                        </Column>
                      </Row>
                      { filterOn && (
                        <div className="mids-table">
                          <Table
                            columns={ columns }
                            data={ this.tableData() }
                            isStriped={ true }
                            defaultSortBy={ columns[0].value }
                            useArrow={ true }
                            editableField={ editableField }
                            editableMidId={ editableMidId }
                            editableFieldValue={ editableFieldValue }
                            onKeyDown={ (event) => this.handleEnterPress(event) }
                            onEditableInputChange={ (value) => this.onEditableInputChange(value) }
                            onDoubleClick={ (row, column) => this.onDoubleClick(row, column) }
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>

        <Modal
          title="Callback URLs"
          handleClose={ this.handleCloseModal }
          showModal={ showModal }
          modalType="text"
          data={ modalData }
        />

        <PromptModal
          title="Change value"
          message={ this.state.changeValueMessage }
          handleConfirm={ this.handleConfirmPromptModal }
          handleClose={ this.handleClosePromptModal }
          isLoading={ false }
          showModal={ this.state.showPromptModal }
        />

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}

      </Row>
    );
  }
}

export default Mids;
