import React, { Component } from 'react';
import Select from "react-select";
import { Tooltip } from 'react-tooltip';

class CustomSelect extends Component {
  state = {};

  sortItems = (items) => {
    items.sort((elemA, elemB) => !elemA.prevent_sorting && !elemB.prevent_sorting && elemA.label.localeCompare(elemB.label));
    return items;
  };

  getSelectedItem = () => {
    const { value, options, withId } = this.props;
    if (!value) {
      return "";
    }

    const option = options.find(elem => elem.value.toString() === value.toString());
    if (!option) {
      return "";
    }

    return withId ? `${option.label} (${option.value})` : option.label;
  };

  render() {
    const { id, value, required, clearable, onChange, options, className, classNamePrefix, disabled, placeholder, showTooltip, enableSort, enableSearch } = this.props;

    return (
      <div style={ { width: "100%" } }>
        {showTooltip && (
          <Tooltip id={ id } />
        )}
        <div
          data-tooltip-id={ id }
          data-tooltip-place="top"
          data-tooltip-variant="light"
          data-tooltip-content={ showTooltip ? (this.getSelectedItem() || "No item selected.") : null }>
          <Select
            name={ id }
            value={ (options && options.length && options.find(option => option.value.toString() === value.toString())) || "" }
            className={ className }
            classNamePrefix={ classNamePrefix }
            isDisabled={ disabled }
            required={ required }
            placeholder={ placeholder || "Select..." }
            isClearable={ clearable }
            isSearchable={ enableSearch }
            menuPlacement="auto"
            onChange={ (event) => onChange(event) }
            styles={ {
              control: (baseStyles, state) => ({
                ...baseStyles,
                minHeight: "44px",
                cursor: "pointer",
                ...(state.isDisabled && {
                  backgroundColor: "#F9F9F9",
                  borderColor: "#CCCCCC"
                })
              }),
              singleValue: (baseStyles, state) => ({
                ...baseStyles,
                ...(state.isDisabled && { color: "#555" })
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                margin: 0,
                zIndex: 100
              }),
              option: (baseStyles) => ({
                ...baseStyles,
                cursor: "pointer"
              })
            } }
            options={ enableSort || enableSort === true ? this.sortItems(options) : options }
          />
        </div>
      </div>
    );
  }
}

export default CustomSelect;
