import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import Select from "@sm/components/custom/Select";

class RunningBalanceComponent extends Component {
  state = {
    selectedItems: [],
    temporarySelectedItem: {},
    showError: false,
    errorMessage: "",
    newRowClicked: false
  };

  componentDidMount() {
    const { selectedItems } = this.props;
    if (selectedItems) {
      this.setState({
        selectedItems: selectedItems
      });
    }
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.selectedItems.length !== newProps.selectedItems.length) {
      this.setState({
        selectedItems: newProps.selectedItems,
        temporarySelectedItem: {}
      });
    }

    if (oldProps.update !== newProps.update) {
      this.setState({
        newRowClicked: false
      });
    }
  }

  onSelectItemChange = (type) => {
    this.setState({
      temporarySelectedItem: Object.assign(this.state.temporarySelectedItem, { id: type.value })
    }, () => {
      const { temporarySelectedItem } = this.state;
      if (temporarySelectedItem.id && temporarySelectedItem.value) {
        this.onTemporaryItemChange();
      } else {
        this.onTemporaryItemChange({});
      }
    });
  };

  onTemporaryItemChange = (sendItem) => {
    const { temporarySelectedItem } = this.state;
    this.props.onTemporaryChange(sendItem || temporarySelectedItem);
  };

  onInputItemChange = (event) => {
    const value = event.target.value.trim();
    this.setState({
      temporarySelectedItem: Object.assign(this.state.temporarySelectedItem, { value })
    }, () => {
      const { temporarySelectedItem } = this.state;
      if (temporarySelectedItem.id && temporarySelectedItem.value) {
        this.onTemporaryItemChange();
      } else {
        this.onTemporaryItemChange({});
      }
    });
  };

  onAddItemChange = () => {
    const { selectedItems, temporarySelectedItem, newRowClicked  } = this.state;

    if (!newRowClicked && selectedItems.length > 0) {
      this.setState({
        newRowClicked: true
      });

      return;
    }

    if (!temporarySelectedItem.value) {
      return;
    }

    selectedItems.push(temporarySelectedItem);
    this.props.onChange(selectedItems);
    this.onTemporaryItemChange({});

    this.setState({
      selectedItems: selectedItems,
      temporarySelectedItem: {},
      showError: false,
      errorMessage: "",
      newRowClicked: true
    });
  };

  onDeleteItemChange = (type) => {
    const { selectedItems } = this.state;

    if (!type) {
      this.setState({
        newRowClicked: false,
        temporarySelectedItem: {},
        showError: false,
        errorMessage: ""
      });

      return;
    }

    const deleteIndex = selectedItems.findIndex(elem => elem.id === type);
    selectedItems.splice(deleteIndex, 1);
    this.props.onChange(selectedItems);
    this.setState({
      selectedItems: selectedItems
    });
  };

  render() {
    const { selectedItems, temporarySelectedItem, newRowClicked, showError, errorMessage } = this.state;
    const props = this.props;
    return <Column className='manual-data-input' flexGrow={ 1 } vertical='start' alignSelf='start'>
      <Column className='manual-data-input-body' flexGrow={ 1 } vertical='start' alignSelf='start'>
        {
          selectedItems.map((elem, i) => {
            return <Row key={ elem.value } flexGrow={ 1 } horizontal='start' className="selected-items">
              <Column className="manual-data-input-column">
                { i === 0 && <label> Type </label> }
                <Select
                  disabled={ true }
                  className="multiselect-select"
                  value={ elem.id }
                  options={ props.items }/>
              </Column>
              <Column className="manual-data-input-column">
                { i === 0 && <label> Value </label> }
                <input
                  readOnly
                  className="form-control"
                  value={ elem.value }/>
              </Column>
              { props.editable && <Row className="actions" flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                <button
                  className="delete"
                  onClick={ () => this.onDeleteItemChange(elem.id) }>
                  X
                </button>
                { (!newRowClicked && i === selectedItems.length - 1) && <button
                  className="add"
                  onClick={ () => this.onAddItemChange() }>
                  <span className="plus"> + </span>
                </button> }
              </Row> }
            </Row>;
          })
        }
        { (selectedItems.length === 0 || newRowClicked) && props.editable && <Row style={ { width: "100%", marginTop: "10px" } } flexGrow={ 1 } horizontal='start'>
          <Column className="manual-data-input-column">
            { selectedItems.length === 0 && <label> Type </label> }
            <Select
              className="manual-data-input-select"
              value={ temporarySelectedItem.id || 'Select' }
              clearable={ false }
              onChange={ (value) => this.onSelectItemChange(value) }
              options={ props.items }
            />
          </Column>
          <Column className="manual-data-input-column">
            { selectedItems.length === 0 && <label> Value </label> }
            <input
              className="form-control"
              type="number"
              value={ temporarySelectedItem.value || '' }
              onChange={ (event) => this.onInputItemChange(event) }/>
          </Column>
          <Row className="actions" flexGrow={ 1 } horizontal='start' vertical='end'>
            { selectedItems.length > 0 && <button
              className="delete"
              onClick={ () => this.onDeleteItemChange("") }>
              X
            </button> }
            <button
              className="add"
              onClick={ () => this.onAddItemChange() }>
              <span className="plus"> + </span>
            </button>
          </Row>
        </Row>
        }
        <Column style={ { width: '100%' } }>
          { showError && <span className="error-message"> { errorMessage } </span> }
        </Column>
      </Column>
    </Column>;
  };
};

export default RunningBalanceComponent;